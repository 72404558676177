import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/ponentes.css';

import ponente1 from '../../assets/images/2023/speakers/ponentes__ALEJANDRO DE KEIJSER TORRES.jpg';
import ponente2 from '../../assets/images/2023/speakers/ponentes__Africa Rubio.jpg';
import ponente3 from '../../assets/images/2023/speakers/ponentes__Ata Renewables.jpg';
import ponente4 from '../../assets/images/2023/speakers/ponentes__Joao Resende.jpg';
import ponente5 from '../../assets/images/2023/speakers/ponentes__Miriam Grunstein.jpg';
import ponente6 from '../../assets/images/2023/speakers/ponentes__Severo_Lopez_Mestre_Arana.jpg';


import ponente7 from '../../assets/images/2023/speakers/ponentes___Alicia Acosta.jpg';
import ponente8 from '../../assets/images/2023/speakers/ponentes___Edgar-Runnebaum.jpg';
import ponente9 from '../../assets/images/2023/speakers/ponentes___Jose Buganza.jpg';
import ponente10 from '../../assets/images/2023/speakers/ponentes__Alicia-Silva.jpg';
import ponente11 from '../../assets/images/2023/speakers/ponentes__ashley albarran.jpg';
import ponente12 from '../../assets/images/2023/speakers/ponentes__Eduardo_Piquero.jpg';
import ponente13 from '../../assets/images/2023/speakers/ponentes_JavierAmozurrutia.jpg';

import ponente14 from '../../assets/images/2023/speakers/ponentes__LeonardoBeltran.jpg';


import ponente15 from '../../assets/images/2023/speakers/ponentes___EnriqueMotolai.jpg';
import ponente16 from '../../assets/images/2023/speakers/ponentes___FedericoLopez.jpg';
import ponente17 from '../../assets/images/2023/speakers/ponentes___MarianaGuerrero.jpg';


import ponente18 from '../../assets/images/2023/speakers/ponentes__claudiavelazqueza.jpg';

const logos = [
    { index:1, img_src: ponente1, nombre: 'Alejandro', apellido: 'De Keijser Torres',
    empresa:'DEACERO',
    descripcion:[<>
    <strong>Responsabilidad Actual</strong><br />
DEACERO es la única empresa que participa activamente en el Mercado Eléctrico Mayorista (MEM) bajo la figura de Usuario Calificado Participante del Mercado, representa más de 400 MW en el MEM lo cual lo convierte en una de las entidades responsables de carga más importantes.<br />
A partir de este año, Deacero apoya al Pacto Mundial de las Naciones Unidas y se compromete al más alto nivel global con los Diez Principios en Derechos Humanos, Normas Laborales, Medio Ambiente y Anticorrupción, así como con los 17 Objetivos de Desarrollo Sostenible.<br />
Como parte de los esfuerzos en Energía y Sustentabilidad, en 2023 se busca incrementar el consumo de energía limpia y presentar las metas de descarbonización, lo que convierte a Deacero en punta de lanza en temas de sustentabilidad en la industria.
<br /><br />
<strong>Experiencia Relevante</strong><br />
Preside la Comisión de Energía del International Chamber of Commerce (ICC) México. Ha realizado diversas publicaciones en prensa especializada del sector energético y es miembro del Observatorio Ciudadano de Energía.<br />
En la CFE se desempeñó como Subgerente de la Subdirección de Distribución, Auxiliar Técnico de la Subdirección de Distribución y Jefe de Oficina Divisional, todos ellos asociados al proceso de distribución y gestión de energía.<br />
A partir de la publicación de la Ley de la Industria Eléctrica fue responsable del cálculo y negociación de la tarifa de Distribución y Suministro Básico. Asimismo, coordinó la participación de la CFE en las primeras dos subastas de largo plazo.<br />
En CFE Calificados, se desempeñó como Coordinador de Gestión de Energía, periodo durante el cual aseguró la adquisición de más de 1,700 MW de capacidad en contratos de largo plazo. Además, se contrataron con clientes finales más de 1,100 MW de capacidad.
Cuenta con una licenciatura en Administración Hotelera (ISGH, Namur, Bélgica) y con una maestría en Administración de Negocios (ESERP, Barcelona, España).
      </>], 
      previu:[<>DEACERO</>]
    },
    { index:2, img_src: ponente2, nombre: 'Africa', apellido: 'Rubio',
    empresa:'',
    descripcion:[<>África Rubio tiene una trayectoria de más de 15 años en el diseño y construcción sustentable, cuenta con las certificaciones LEED y WELL y con su experiencia ha brindado servicios en el ramo a empresas nacionales e internacionales. Actualmente dirige el área de Sustainability Services de Cushman and Wakefield, en la que también brinda asesoría en accesibilidad universal e inclusión.</>], 
      previu:[<>Project & Development Services</>]
    },
    { index:4, img_src: ponente4, nombre: 'Joao', apellido: 'Resende',
    empresa:'',
    descripcion:[<>Ingeniero Mecánico por la Universidad de Porto, en Portugal, Especialidad en Administración Energética por la Egade Business School en Ciudad de México. Certificado como PMVA (Performance Measurement and Verification Analyst) para la medición y verificación de ahorros en proyectos de eficiencia energética.<br /><br />
      Empezó su experiencia profesional con proyectos de energía solar en Portugal y España. Fue Auditor Energético y Proyectista de HVAC para edificios de servicios en Portugal. <br /><br />
      A partir de 2013  colaboró con Ecochoice México como Director Técnico y en 2017 asumió funciones de Director de Operaciones.<br /><br />
      En 2022 se unió a Siemens como Director de Energy and Performance Services para México, Centro América y Caribe dónde se dedica a soluciones de descarbonización, eficiencia energética y generación distribuida. <br /><br />
      Fue tesorero de la Asociación Mexicana de Empresas de Eficiencia Energética (AMENEER) para el período de 2020-22 y es actualmente el Vicepresidente.
      </>], 
      previu:[<></>]
    },
    { index:5, img_src: ponente5, nombre: 'Miriam', apellido: 'Grunstein',
    empresa:'',
    descripcion:[<>La Dra. Miriam Grunstein es licenciada con mención honorífica en derecho por el Instituto Tecnológico Autónomo de México, licenciada en estudios latinoamericanos por la Universidad de Nuevo México y maestra y doctora en letras de la escuela de ciencias y artes de New York University, también con la máxima mención honorífica.
<br /><br />
      Su trayectoria dentro del sector energético inicia hace 20 años en la Comisión Reguladora de Energía, donde formó parte de importantes equipos de trabajo dedicados al análisis jurídico de disposiciones legales y reglamentarias en materia energética y administrativa;  a la realización de instrumentos de regulación para proyectos energéticos de gran escala (de Petróleos Mexicanos, Shell, Chevron-Texaco, Sempra); y a la elaboración de resoluciones para votación de la  Comisión en materia de políticas de regulación energética. Participó activamente en las primeras resoluciones que otorgaron los primeros permisos para parques eólicos. 
      <br /><br />
      En la iniciativa privada, ha sido consultora jurídica externa de despachos nacionales e internacionales (Thompson & Knight, Chadbourne & Parke, y Holand & Knight) en donde ha tenido a su cargo proyectos de gran escala como gasoductos, terminales de GNL, permisos para centrales eléctricas, contratos de arrendamiento de plataformas petroleras, contratos de gas natural de grandes adquirentes y ha participado como en  equipos de peritos en arbitrajes internacionales en materia energética. 
      <br /><br />
      La Dra. Grunstein es autora de numerosas publicaciones nacionales e internacionales y ha participado en casi un centenar de foros, nacionales y extranjeros. Su libro De la Caverna al Mercado, una Vuelta a la Contratación Petrolera se considera un referente dentro de la industria. Ha sido galardonada con el premio Robert Cooter por la Academia Mexicana de Derecho y Economía, el premio Fernando Cuevas por CEPAL por el mejor ensayo sobre energía y cambio climático y ha figurado por más de 5 veces veces en la lista de los 100 líderes más influyentes del sector energético de la revista Petróleo y Energía. En 2014 su trabajo de investigación ameritó el reconocimiento en la categoría Académico Científica de esta misma revista. De la misma forma, fue galardonada con la Beca Matías Romero de la Secretaría y la Universidad de Texas en Austin. 
      <br /><br />
      Grunstein es Académica Afiliada del Mexico Center de James Baker III de Rice University, donde desarrolla trabajos de investigación sobre energías renovables, transición energética, desarrollo de nuevas tecnologías, sustentabilidad y compromisos internacionales para la reducción de emisiones y combate al cambio climático. 
      <br /><br />
      Además de participar como entrevistada en los principales medios de comunicación, es columnista de Expansión  y del blog de México, ¿Cómo Vamos? en Animal Político. 
      </>], 
      previu:[<></>]
    },
    { index:6, img_src: ponente6, nombre: 'Severo', apellido: 'López Mestre Arana',
    empresa:'',
    descripcion:[<>Socio Director de Galo Energy Consulting. Miembro fundador y consejero ejecutivo de SUMe (Sustentabilidad para México AC representación del World Green Building Council en México).<br /><br />
      Es experto en regulación y política energética con más de 25 años de experiencia incluyendo la Comisión Federal de Electricidad, la Secretaría de Energía, despachos especializados en el tema, así como en el desarrollo de proyectos de energía limpia y almacenamiento con empresas globales de Asia, Europa y Norteamérica. <br /><br />
      Es egresado de la carrera de Derecho del Instituto Tecnológico Autónomo de México ITAM, Maestro por la Universidad de Chicago en regulación y competencia económica y con especialidades en Administración Estratégica de Instituciones Públicas y Administración Estratégica y Análisis de Políticas del Sector Eléctrico por la Universidad de California en Berkeley.<br /><br />
      Ha sido profesor en la maestría de Políticas Públicas y la maestría de Derecho Administrativo y Regulación en el ITAM, así como instructor en regulación económica para la Secretaría de Energía y miembros del Congreso de la Unión.<br /><br />
      Consultor y asesor para fondos de inversión en infraestructura en América, Europa, Medio Ofiente y Asia.<br /><br />
      Ha colaborado con artículos especializados, publicaciones y entrevistas en diversos medios como ADN40, EFBloomberg-tv, China Tv., Deutsche Welle, Aljazeera, El Financiero, El Universal, Milenio, Arena Publica, el Periódico Reforma, Forbes Latinoamérica, Grupo Expansión, Editorial Porrúa, ITAM, El País, S&P Global, Natural Gas Intelligence, Bloomberg News, etc.
      </>], 
      previu:[<></>]
    },
    { index:7, img_src: ponente7, nombre: 'Alicia', apellido: 'Acosta',
    empresa:'',
    descripcion:[<>
    La trayectoria de Alicia Acosta, nuestra vice presidenta en la Mesa Directiva del Periodo 2021-2023, abarca el recibimiento del Greenbuild Leadership Award que otorga el USGBC en su calidad de responsable del área de sustentabilidad a nombre de la empresa FINSA.
    <ul>
      <li>Es estratega en la creación y gestión estrategias de sustentabilidad.</li>
      <li>Tiene a su cargo el área del área de proyectos sustentables en la que busca la Implementación de mejores prácticas sustentables para los proyectos (eficiencia energética, planes de mejora de la calidad del aire, gestión de residuos).</li>
      <li>Desarrollo y seguimiento de las certificaciones verdes para los proyectos, como LEED y EDGE.</li>
      <li>Responsable del programa LEED Volume.</li>
      <li>Creación de estrategias para disminuir los impactos al medio ambiente relacionadas con la construcción y las actividades de la empresa.</li>
      <li>Responsable del cumplimiento de las normativas ambientales en construcción.</li>
      <li>Desarrollo e implementación de capacitaciones en materia ambiental.</li>
    </ul>
      </>], 
      previu:[<></>]
    },
    { index:8, img_src: ponente8, nombre: 'Edgar Fernando', apellido: 'Runnebaum Palau',
    empresa:'',
    descripcion:[<>
    El Lic. Edgar Fernando Runnebaum Palau cuenta con más de 30 años de experiencia laborando en empresas de tecnología de punta, habiendo desarrollado su carrera profesional en Costa Rica, Estados Unidos y México, pero en funciones que le han permitido desarrollarse en mercados de Norte, Centro y Sur América, así como en Europa y Asia.<br /><br />
    El Lic. Runnebaum se desempeña en la actualidad como Director de Siemens Real Estate y como Sustainability Manager de Siemens para la Región Mesoamérica, abarcando los mercados de México, Centroamérica y el Caribe.  Posee una carrera de más de 20 años en Siemens que inició en su natal Costa Rica, de donde se trasladó a México hace más de 16 años para seguir desarrollándose dentro de la citada empresa.<br /><br />
    Dentro de Siemens se ha desempeñado como Gerente Comercial del Sector Energía en Costa Rica, Gerente Comercial para Power Transmission and Distribution Mesoamérica en México, Gerente de Estrategia y Desarrollo de Negocios para la región Mesoamérica en el Sector Energía, Director de Siemens Real Estate para la región desde 2012 y desde 2020 conjuntamente se desempeña como Sustainability Manager para Mesoamérica.<br /><br />
    Anteriormente fue Director de Ventas de la empresa Seguridad y Manutención, S.A. en San José, Costa Rica en donde laboró por más de 12 años, empresa en la que logró desarrollar y potencializar la fabricación y comercialización de equipos de seguridad física en cuatro continentes.  <br /><br />
    El Lic. Runnebaum es egresado de Administración de Negocios en Costa Rica, posee un posgrado en Marketing de Productos Industriales de Ferris State University, Michigan, USA y es diplomado en Desarrollo Gerencial por el ITAM, México; Liderazgo por la Universidad Anáhuac México Norte; Administración de Empresas de la Construcción por el Tecnológico de Monterrey, México; Real Estate Management por la Universidad de Harvard y Corporate Sustainability Management por la Universidad de Yale.
      </>], 
      previu:[<></>]
    },
    { index:9, img_src: ponente9, nombre: 'Jose', apellido: 'Buganza Debernardi',
    empresa:'ENEGENCE',
    descripcion:[<>
    José es un ejecutivo con una vasta trayectoria en diversas industrias del sector público y privado, dentro de los cuales destaca su participación de más de 11 años en el sector eléctrico. <br /><br />
    José cuenta con amplia trayectoria en el sector de petróleo y energía eléctrica, particularmente desarrollando evaluación de proyectos, modelos de nuevos negocios, planeación, precios y riesgos. Desde el 2014, ha participado activamente en la Reforma Energética, en el sector eléctrico, coordinando activamente los esfuerzos para implementar el Mercado Eléctrico Mayorista desde la Secretaría de Energía. Posteriormente, colaboró como CFO con el Centro Nacional de Control de Energía y actualmente como Director General de Enegence, empresa de consultoría regulatoria, estratégica y de operaciones que además desarrolla software para que los integrantes de la industria eléctrica lleven a cabo sus operaciones y miembro del consejo de administración de distintas empresas de energía y tecnología.<br /><br />
    José cuenta con una maestría en administración en la universidad de Stanford y es Ingeniero Industrial de la Universidad de las Américas, Puebla.
      </>], 
      previu:[<></>]
    },
    { index:10, img_src: ponente10, nombre: 'Alicia', apellido: 'Silva',
    empresa:'LEED FELLOW',
    descripcion:[<>
    Presidente de Sustentabilidad para Mexico, SUME 2021-2023 y directora y fundadora de Revitaliza Consultores desde 2009. Arquitecta por la Universidad de las Américas de Puebla y con Maestría en Administración de Empresas Socio-ambientales. Especialidad como Sustainable Building advisor por el Seattle community college, 2006. Es líder en consultoria en edificaciones sustentables, reconocida como LEED Fellow por el GBCI en el año 2020 por su experiencia de más de 18 años de liderazgo y trabajo único dentro de la industria de la construcción verde. <br /><br />
        Alicia tiene más de 20 años de experiencia en el área de la sustentabilidad a nivel internacional. Ha desarrollado su trabajo en EUA, México y Latinoamérica, liderando más de 65 procesos de certificación LEED, con mas de 50 certificaciones exitosa, donde ya se incluyen certificaciones de WELL, Sustainable Sites y EDGE. Actualmente trabaja con portafolios inmobiliarios en temas de ASG creando estrategias ambientales, su incidencia en el medio permite que su experiencia pueda impactar a compañías cada vez más grandes y proyectos a escala. <br /><br />
        Ha participado en comités técnicos en varias organizaciones como USGBC, siendo de las pocas mexicanas con incidencia en el LEED Steering Committee o LEED Advisory Committee y en los comités técnicos de WELL. Encargada de ASHRAE mujeres en capítulo de la CDMX. En 2015 recibe el premio Mujeres en el liderazgo sustentable como una de las 20 mujeres cambiando el panorama de la sustentabilidad a nivel mundial y en 2017 su compañía es reconocida como una de las 30 promesas de negocios por la revista Forbes.<br /><br />
        Su participación activa como transformadora del mercado de la edificación en México y América latina hizo que se le reconociera con el premio de Liderazgo en la construcción verde, otorgado por el USGBC a todo LATAM en 2019. Alicia trabaja activamente en la creación de bienestar en el espacio construido para todos.
      </>], 
      previu:[<>
        </>]
    },
    { index:11, img_src: ponente11, nombre: 'Ashley', apellido: 'Albarran',
    empresa:'',
    descripcion:[<>
    “Ashley Albarrán es Maestra en Economía y Negocios, y Licenciada en Dirección y Administración de Empresas por la Universidad Anáhuac. Se ha especializado en materia de Finanzas Sostenibles y Sustentabilidad. Está certificada en en ESG Investing por el CFA Institute y en  International Sustainable Finance por la International Association for Sustainable Economy (IASE). Además, cuenta con diplomados en Financiamiento Climático por la Pontificia Universidad Javierana de Colombia; en Finanzas Sostenibles por EF Business School; en Inversión de Impacto y Economía Regenerativa por Regenera Academy de SVX México; y en Gestión Estratégica de la Responsabilidad Social Empresarial por el Global Compact Regional Center for Latin America; entre otros. <br /><br />
    Actualmente se desempeña como Gerente de Finanzas Sostenibles para México y Latinoamérica en HSBC, pero cuenta con experiencia en consultoría de negocios, fungiendo como Consultora Sr. en Finanzas Sostenibles en la firma española Valora Consultores y cuenta también con experiencia en finanzas, siendo la Tesorera para México de la compañía de seguros anglo-americana XL Catlin.<br /><br />
    Adicionalmente, fue una de los 500 jóvenes seleccionados en 17 países para formar parte del Programa de Jóvenes Latinoamericanos sobre Finanzas Sostenibles del Climate Finance Group of Latin America and the Caribbean (GFLAC) y participa en el Programa de Mujeres por las Finanzas Sostenibles de LatAm de GFLAC.”
      </>], 
      previu:[<></>]
    },
    { index:12, img_src: ponente12, nombre: 'Eduardo', apellido: 'Piquero',
    empresa:'',
    descripcion:[<>La experiencia de Eduardo en políticas e instrumentos de fijación de precio al carbono en México y a nivel internacional abarca más de 16 años. Actualmente se desempeña como CEO de MÉXICO2, una empresa del Grupo Bolsa Mexicana de Valores, donde supervisa el desarrollo de mercados ambientales, incluidos los mercados de bonos verdes y de carbono. En colaboración con la Secretaría de Medio Ambiente de México, Eduardo lideró el desarrollo del ejercicio de simulación de un Sistema Mexicano de Comercio de Emisiones (SCE Mx o Mx ETS, por sus siglas en inglés) en preparación para el mercado regulado del país. Eduardo también participó en la creación de un mercado secundario de Certificados de Energía Limpia, que apoya al Mercado Eléctrico Mayorista Mexicano. Además de su trabajo en México, Eduardo se ha centrado en el desarrollo de proyectos de reducción de emisiones de gases de efecto invernadero y ha diseñado proyectos de cambio climático en países de América Latina, Asia y África.
      </>], 
      previu:[<></>]
    },
    { index:13, img_src: ponente13, nombre: 'Javier', apellido: 'Amozurrutia Elizalde',
    empresa:'',
    descripcion:[<>Estudió Mercadotecnia y se ha desarrollado en diversas empresas, principalmente en áreas comerciales y de mercadotecnia. En sus últimos años, colaboró en empresas internacionales en posiciones de Dirección. Su visión emprendedora lo ayudó para formar parte del comité internacional de Innovación y de ahí conocer lo último en Movilidad en las empresas y en otros países. En los últimos 6 años se ha dedicado al estudio de las tendencias de movilidad y en el 2020 fundó Mazmobi lo que lo ha llevado a presentar su modelo en foros internacionales como la Expo Dubai y Motivate Vancouver. En el 2023 lanzó la iniciativa para el desarrollo de Hubs de Movilidad Eléctrica, una alianza que busca conjuntar a todas las empresas en pro de una movilidad sustentable.
      </>], 
      previu:[<></>]
    },
    { index:14, img_src: ponente14, nombre: 'Leonardo', apellido: 'Beltrán',
    empresa:'',
    descripcion:[<>Leonardo Beltrán tiene más de 20 años de experiencia. Es miembro de la Junta Directiva de Fundación por México; de la Iniciativa de Evaluación y Aprendizaje de los Climate Investment Funds; miembro del grupo de asesoría técnica de ESMAP del Banco Mundial; del Panel de Expertos del Consejo Nacional de Transición Energética de Panamá; de la iniciativa del Foro Económico Mundial Accelerating Clean Hydrogen y de su Red de Expertos; y mentor del programa regional de América Latina de la Global Women ́s Network for the Energy Transition.<br /><br />
      El Sr. Beltrán también es Distinguished Visiting Fellow del Centro sobre Política Energética Global de la Universidad de Columbia, Non-Resident Fellow del Instituto de las Americas; Executive Fellow y miembro del Comité Asesor del Programa de Gobernanza de Recursos Extractivos de la Escuela de Política Pública de la Universidad de Calgary; asociado del Consejo Mexicano de Asuntos Internacionales; miembro del consejo editorial de la revista Enerlac de la Organización Latinoamericana de Energía (OLADE); y miembro de la asamblea de IPS Noticias.<br /><br />
      El Sr. Beltrán también se desempeña como consultor del Gobierno de Jamaica. Fue Subsecretario de Planeación y Transición Energética (2012-2018), Director General de Información y Estudios Energéticos (2010-2012), Director de Negociaciones Internacionales (2005-2010) y Consultor de BBVA Bancomer (2000-2003). También fue miembro de la Junta Administrativa de Sustainable Energy for All. El Sr. Beltrán ha sido consultor para el Banco Interamericano de Desarrollo, el Banco Mundial, la Organización Latinoamericana de Energía, la Organización de Naciones Unidas para el Desarrollo Industrial y la Organización para la Cooperación y el Desarrollo Económicos.<br /><br />
      Tiene una Maestría en Administración Pública en Desarrollo Internacional de la Escuela Kennedy de la Universidad de Harvard, una licenciatura en economía del Instituto Tecnológico Autónomo de México (ITAM) y estudió derecho en la Universidad Nacional Autónoma de México (UNAM).
      </>], 
      previu:[<></>]
    },
    { index:15, img_src: ponente15, nombre: 'Enrique', apellido: 'Motolá',
    empresa:'THREE',
    descripcion:[<>Enrique es ingeniero industrial experto en áreas de planeación, ventas, desarrollo organizacional y recursos humanos con más de 15 años de experiencia.<br /><br />
      Se ha desarrollado en distintas áreas, comenzando su trayectoria profesional en el sector automotriz dentro de Nemak - en el área de Planeación Central, como responsable de los proyectos de inversión de capital para las plantas de Estados Unidos, Canadá, y China.<br /><br />
      Colaboró 7 años con la prestigiosa empresa Michael Page - empresa líder en el reclutamiento de ejecutivos a nivel internacional - primeramente, en la oficina de Ciudad de México, para posteriormente desarrollar las áreas de Ingeniería y Manufactura y Cadena de Suministro durante el lanzamiento de la oficina de Monterrey.<br /><br />
      Durante 2018-2020, inició la filial de Monterrey de Collective Academy - una neo-universidad mexicana que está reinventando la educación para Latinoamérica, estableciendo la primera oficina fuera de CDMX y donde se mantiene como mentor desde el 2018.<br /><br />
      Enrique cuenta con las acreditaciones de Certified Energy Manager por parte de la Asociación de Ingenieros en Energía y es LEED® Accredited Professional por parte de Green Building Certification Inc.<br /><br />
      Actualmente y desde el 2017, es socio y director de crecimiento de THREE Consultoría Medioambiental - empresa especialista en desarrollar soluciones sostenibles, holísticas y de ASG (Ambientales, Sociales y de Gobernanza), con un portafolio de más de 190 proyectos sostenibles, nacionales e internacionales, y 100 certificados, de los cuales 93 proyectos son certificados LEED®.
      </>], 
      previu:[<></>]
    },
    { index:16, img_src: ponente16, nombre: 'Federico', apellido: 'López de Alba',
    empresa:'',
    descripcion:[<>Ingeniero Químico por la Universidad de Guanajuato, con Maestrías en Economía  y Administración de Empresas por la Universidad de las Américas, maestría en Ingeniería Química con especialización en Ingeniería de Control de la Contaminación por Manhattan College, New York; Doctorado en Ciencias Administrativas del Instituto Politécnico Nacional<br /><br />
      Participé en la integración de la Subsecretaría de Mejoramiento del Ambiente de la Secretaría de Salubridad y Asistencia, participé en el diseño y operación de la Red de Monitoreo Automático y fui  su primer director. Subdirector de nivel nacional de Ecología de los Asentamientos Humanos de la Secretaría de Asentamientos Humanos y Obras Públicas. Creador y primer director del Instituto de Seguridad en el Trabajo del gobierno del Estado de México; experto en Impacto y Riesgo Ambiental; experto en Salud Ocupacional. Para Bufete Industrial trabajé 7 años como asesor de proyectos, en el área de Petroquímica, Plantas de Energía e Ingenios Azucareros.<br /><br />
      Desde los años ochenta he trabajado en la estructuración y armonización entre  medio ambiente y energía,  con énfasis en la economía verde y economía de la energía. He sido consultor externo de varios organismos de la ONU en protección ambiental, seguridad energética, economía Verde, e incorporación de ASG en el desarrollo de proyectos y Cambio Climático.<br /><br />
      Desde 2000, colaboro en CFE, actualmente como encargado de la Gerencia de Protección Ambiental y antes subgerente de evaluación de emisiones, en la actual Dirección Corporativa de Ingeniería y Desarrollo de Proyectos (DCIPI).<br /><br />
      He participado muy activamente en la esfera internacional como experto en los temas de seguridad energética, cambio climático, tecnologías de control ambiental y tecnología de producción más limpia.<br /><br />
      He participado como miembro de las delegaciones mexicanas a las sesiones de UNEP, de Combate a la Desertificación, Protección de los Océanos, CMNUCC.
      </>], 
      previu:[<></>]
    },
    { index:17, img_src: ponente17, nombre: 'Mariana', apellido: 'Guerrero',
    empresa:'',
    descripcion:[<>Mariana Guerrero es una profesional de marketing con 15 años de experiencia en México, América Latina y el Caribe.  En el mercado de TI y TELECOM y en los últimos años en Energías Renovables. Desde hace 3 años se ha desempeñado como Gerente de marketing para Latinoamérica en SunPower From Maxeon Solar Technologies, donde diariamente impacta positivamente trabajando con clientes, instaladores y dueños de casa a hacer realidad un mundo más sostenible e inclusivo. En esta posición y durante toda su carrera ha desarrollado la capacidad de satisfacer las necesidades de los consumidores actuales encontrando siempre estrategias de promoción que se centran en minimizar el impacto negativo en el medio ambiente y la sociedad utilizando prácticas como la reducción de residuos, descarbonización, promover transparencia social y apoyar causas sociales. Entusiasta
      </>], 
      previu:[<></>]
    },
    { index:18, img_src: ponente18, nombre: 'Claudia', apellido: 'Velázquez A.',
    empresa:'',
    descripcion:[<>
    Lic. En Economía y Maestría en Administración de Empresas.<br /><br />
Instituto Tecnológico y de Estudios Superiores de Monterrey, Campus Monterrey.<br /><br />
Directora de Operaciones, Softec, Consultoría en Proyectos Inmobiliarios.<br /><br />
Responsabilidad de la ejecución de poco más de 30 estudios de mercado por mes para proyectos inmobiliarios habitacionales, comerciales, turísticos, oficinas y mixtos en México.<br /><br />
Responsabilidad de la edición y distribución de 43 actualizaciones trimestrales de la revista inmobiliaria DIME, que reporta, desde 1990, la situación de los principales 40 mercados de vivienda nueva terminada en México.<br /><br />
Encargada de las áreas de información, procesamiento y consultoría de Softec, para el desarrollo de las publicaciones, estudios, y asesoría inmobiliaria de la empresa.<br /><br />
Co-editora e investigadora para el Mexican Housing Overview. Investigación especializada de Softec, S.C. que analiza el panorama y tendencias de la vivienda en México en forma anual.<br /><br />
Colaboradora en publicaciones especializadas del sector inmobiliario, así como en revistas de Cámaras y Asociaciones relacionadas con el sector vivienda y constructor.<br /><br />
Conferencista en diversos foros inmobiliarios nacionales y de Latinoamérica
      </>], 
      previu:[<></>]
    }
    
];




const CarouselPonentes = (props) => {

  const ref_carousel = React.createRef();
  const [activar_carouse, setActivaCarosel] = useState(0);
  const {idioma} = props;
  const [select_persona, setSelectPersona] = useState(0);
  const [ver_modal, setVerModal] = useState(false);

    const ver_personas = logos.map((item) => {
      return(
        <>
        <Col span={5}>
        <div className="img_contenedor_ponente">
        <img src={item.img_src} alt={item.nombre} className="img_ponente" />
        <div className="overlay_ponente">
              <div className="text_ponente">
                <span className='nombre_hover_ponente'>{item.nombre} {item.apellido}</span><br />
                <span className='previu_hover_ponente'>{item.previu}</span><br /><br />
                <span onClick={()=>{setVerModal(true);setSelectPersona(item.index)}} className='masinfo_hover_ponente'>Bio</span>
              </div>
            </div>
          </div>
          <br />
          </Col>
        </>
      )
    });
    const ver_personas_movil = logos.map((item) => {
      return(
        <>
        <div className="img_contenedor_ponente">
        <img src={item.img_src} alt={item.nombre} className="img_ponente" />
        <div className="overlay_ponente">
              <div className="text_ponente">
                <span className='nombre_hover_ponente'>{item.nombre} {item.apellido}</span><br />
                <span className='previu_hover_ponente'>{item.previu}</span><br />
                {item.index === 10 ?
                <></>
                :
                <span onClick={()=>{setVerModal(true);setSelectPersona(item.index)}} className='masinfo_hover_ponente'>{idioma === 'es' ? 'Más información' : 'More information'}</span>
    }
              </div>
            </div>
          </div>
        </>
      )
    });
    const ver_info_personas = logos.filter((personas) =>{
      if(personas.index === select_persona)
      return true;
      else
      return false;
    }).map((item) => {
      return(
        <>
          <Row justify='center'>
            <Col xxs={20} md={20}style={{padding:'5px 10px'}}>
            <p className='nombre_modal_ponente'>{item.nombre} {item.apellido}</p>
            <p className='descripcion_modal_ponente'>{item.descripcion}</p>
            </Col>
          </Row>
        </>
      )
    });
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        
        <CaretRightFilled className={className} style={{ ...style, display: "block", color:'black', fontSize:'34px' }} onClick={onClick} />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'black', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
      );
    }

    const settingsPonentes = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows:true,
      nextArrow:<SampleNextArrow /> ,
      prevArrow:<SamplePrevArrow  />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        }
      ]
    };

    const settingslogos = {
            infinite: true,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 10000,
            speed:2000,
            dots: false,
            adaptiveHeight: true
    };

    const cambiarCarousel = (adelante) => {
      if(adelante){
          if(activar_carouse === 5){
            setActivaCarosel(1);
          }else{
            setActivaCarosel(activar_carouse+1);
          }
          ref_carousel.current.slickNext();
      }else{
          if(activar_carouse === 0){
            setActivaCarosel(5);
          }else{
            setActivaCarosel(activar_carouse-1);
          }
          ref_carousel.current.slickPrev();
      }
      
      }

    return (
      <>
      <Row align="middle" justify="center">
      <Col span={20}>
        <br /><br />
        <p className='titulo_patrocinadores'>SPEAKERS</p>
      </Col>
      </Row>
      <Row justify="center" align="middle" className='contenedor_ponente'>
        
          <Col span={0}>
          <CaretLeftFilled className="flecha_agenda" onClick={() => {cambiarCarousel(false);}}/>
          </Col>
        <Col xs={0} md={16}>
        <Row justify="space-around">
          {ver_personas}
        </Row>
        </Col>
        <Col xs={16} md={0}>
        <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_personas_movil}
        </Slider>
        </Col>
        <Col span={0}>
          <CaretRightFilled className="flecha_agenda" onClick={() => {cambiarCarousel(true);}}/>
          </Col>
        {/*<div className="col_flechas_ponentes_izquierda">
        <img alt="" src={flecha_izquierda} className="img_carousel_flecha flecha_izquierda" alt="carousel_izquierda" onClick={() => cambiarCarousel(false)} />
        </div>
        <div  className="col_flechas_ponentes_derecha">
        <img alt="" src={flecha_derecha} className="img_carousel_flecha flecha_derecha" alt="carousel_derecha" onClick={() => cambiarCarousel(true)} />
        </div>*/}
      </Row>
      <Modal 
      visible={ver_modal} onCancel={() => { setVerModal(false); }}
      title={null}
      footer={null}
      closable={true}
      width="750px"
                className=""
                forceRender={true}>
        {ver_info_personas}
      </Modal>
    </>
    );

};

export default CarouselPonentes;