import React, { useState } from 'react';
import { Row, Col, Carousel, Button, Typography, Input } from 'antd';

const { Text } = Typography;

let it_pregunta = 1;
let usuario_respuestas = {};

export const Preguntas = (props) => {

    const [respuesta_elegida, setRespuestaElegida] = useState(false);
    const { preguntas, onResponse, idioma, dia } = props;
    const refCarouselPreguntas = React.createRef();

    //console.log(preguntas);

    const onChangeRespuesta = (value) => {
        usuario_respuestas['pregunta' + it_pregunta] = value.target.value;
        setRespuestaElegida(true);
    }

    const siguientePregunta = () => {
        setRespuestaElegida(false);
        ++it_pregunta;
        if (it_pregunta > preguntas.length) {
            onResponse(usuario_respuestas);
        } else {
            refCarouselPreguntas.current.next();
        }

    }

    let divs_preguntas = preguntas.map(pregunta => {

        const respuestas = Object.values(pregunta.respuestas).map(({respuesta, respuesta_ingles}) => {
            return (
                <Row align="middle" style={{ textAlign: 'center', marginTop: '15px' }}>
                    <Col span={1} style={{ textAlign: 'right' }}>
                        <Input name="resp" type="radio" value={idioma === 'es' ? respuesta : respuesta_ingles} onClick={onChangeRespuesta} style={{border:'0px solid white !important'}} />
                    </Col>
                    <Col span={22} offset={0} style={{ textAlign: 'left', paddingLeft:'5px' }}>
                        <Text className="texto_respuesta_preguntas">{idioma === 'es' ? respuesta : respuesta_ingles}</Text>
                    </Col>
                </Row>
            );
        });
        return (
            <div>
                <Row style={{border:'1px solid lightgray', background: 'white'}}>
                <Col span={24} style={{ backgroundColor: '#ffffff', paddingTop:'10px', paddingBottom:'10px', borderBottom:'3px solid #f47925' }} >
                        <Text strong style={{ color: '#666666', fontSize: '16px' }}>
                            {idioma === 'es' ? pregunta.pregunta : pregunta.pregunta_ingles}
                        </Text>
                    </Col>
                    <Col span={22} offset={1}>
                        {respuestas}
                    </Col>
                    <Col span={24} style={{  textAlign:'right' }}>
                    {idioma === 'es' ?
                    <button className="btn_siguiente_pregunta" onClick={siguientePregunta} disabled={!respuesta_elegida} >VOTAR</button>
                :
                <button className="btn_siguiente_pregunta" onClick={siguientePregunta} disabled={!respuesta_elegida} >VOTE</button>}
                    </Col>
                </Row>


            </div>
        );


    });

    return (
        <Carousel dots={false} ref={refCarouselPreguntas} effect="fade">
            {divs_preguntas}
            
        </Carousel>
    );

}
