import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import './App.css';
import { Master } from './componentes/master/Master';
import 'antd/dist/antd.css';
import ScrollHandler from './componentes/master/ScrollHandler';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollHandler />
        <Route path="/" component={Master} />
      </BrowserRouter>
    </div>
  );
}

export default App;
