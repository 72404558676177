import React, {useState, useEffect, useContext} from 'react';
import '../../assets/css/admin.css';
import Firebase from '../../utils/firebaseConfig';
import { Row, Col, Select, notification  } from "antd";
import { Auth } from "../../utils/AuthContext";
import { faIgloo } from '@fortawesome/free-solid-svg-icons';

const nombre_webinar = 'ies-2023';

const mensaje = (tipo, titulo, texto) =>{
  notification[tipo]({
      message: titulo,
      description: texto,
      duration: 10,
    });
}

const firebase = new Firebase();

const { Option } = Select;

const acceso_admin = ['martin@thethree.com.mx','martha@thethree.com.mx','marco@thethree.com.mx',
                      'andy@thethree.com.mx','mario@thethree.com.mx','federico@zeraus.com.mx',
                      'j.villasenor1021@gmail.com'];

const Admin = (props) => {

  const [questions, setQuestions] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [dia, setDia] = useState(-1);
  const [pop_pregunta, setPopPregunta] = useState(-1);

  const { usuario, datos } = useContext(Auth);

  useEffect(()=>{

    if(usuario){
      if(datos){
        const acceso = acceso_admin.filter(correo => correo === datos.correo).length;
        if(acceso > 0){
          firebase.obtener_objetos(nombre_webinar+`/configuracion`,false,(data)=>{
            setPopPregunta(data[0].pregunta_popup);
            setDia(data[0].dia);
            firebase.obtener_objetos(nombre_webinar+`/preguntas-publico${data[0].dia}`,true,(data)=>{
              setQuestions(data);
              console.log(data);
            });
            firebase.obtener_objetos(nombre_webinar+'/preguntas-popup',false,(data) => {
              setPreguntas(data.filter(pregunta => pregunta.dia === data[0].dia));
            });
      
          });
        }else{
          alert('no tienes acceso a esta seccion');
        }
      }else{
        alert('no tienes acceso a esta seccion');
      }
    }else{
      alert('no tienes acceso a esta seccion');
    }
  },[usuario,datos]);

  useEffect(()=>{},[dia]);

  const aceptQuestion = (item) => {

    item['status'] = 'aceptada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{
      
    });
  };

  const rejectQuestion = (item) => {

    item['status'] = 'rechazada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const answerQuestion = (item) => {

    item['status'] = 'contestada';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const retrieveQuestion = (item) => {

    item['status'] = 'pendiente';
    firebase.escribir(nombre_webinar+`/preguntas-publico${dia}`, item.key, item, ()=>{

    });
  };

  const cambiarPreguntaPopUp = (e) => {

    firebase.escribir(nombre_webinar+'/configuracion','1234567890',{pregunta_popup: e, dia: dia}, ()=> {
      mensaje('success','Se actualizo la pregunta','');
    } );

  }

  

    let preguntas_pop_up = preguntas.map(pregunta => {

      return(
      <Option value={pregunta.num_pregunta}>{pregunta.pregunta}</Option>
      );
    });


    return (
      <>
      <Row>
        <Col span={20} offset={2}>
          <br /><br /><br /><br />
        <p className="admin_titulo">ENCUESTA SATISFACCIÓN</p>
        {pop_pregunta !== -1 &&
        
        <Select defaultValue={pop_pregunta} style={{ width: '100%' }} onChange={cambiarPreguntaPopUp}>
          <Option value={0}>Desactivar</Option>
          { preguntas_pop_up }
        </Select>
        }
        </Col>
      </Row>

      <Row>

        <Col span={24} offset={0}>
          <p className="admin_titulo">PREGUNTAS</p>
        </Col>
        <Col></Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">PENDIENTES</p>
            </Col>
            <Col span={24} >
            {questions.map((item,idx)=>{
              return  item.status === 'pendiente' ? (
              <div className="questionContainer" key={item.key}>
                <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
                <p className="question"><strong>{item.pregunta}</strong></p>
                <p className="botones">
                  <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}>RECHAZAR</button>
                  <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}>ACEPTAR</button>
                </p>
                </div>) : null;
            })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">ACEPTADAS</p>
            </Col>
            <Col span={24}>
            {questions.map((item,idx)=>{
          return  item.status === 'aceptada' ? (
          <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span><br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="rejectButton" onClick={()=>{
                    rejectQuestion(item);
                  }}>RECHAZAR</button>
            <button className="answerButton" onClick={()=>{
              answerQuestion(item);
            }}>CONTESTADA</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">RECHAZADAS</p>
            </Col>
            <Col span={24}>
            {questions.map((item,idx)=>{
            return  item.status === 'rechazada' ? (
            <div className="questionContainer" key={item.key}>
              <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
              <p className="question"><strong>{item.pregunta}</strong></p>
              <p className="botones">
                <button className="retrieveButton" onClick={()=>{
              retrieveQuestion(item);
            }}>RECUPERAR</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
        </Col>
        <Col span={6} className="admin_preguntas_contendor">
          <Row>
            <Col span={24} className="admin_encabezado">
              <p className="admin_texto_encabezado">CONTESTADAS</p>
            </Col>
            <Col span={24} >
            {questions.map((item,idx)=>{
            return  item.status === 'contestada' ? (
            <div className="questionContainer" key={item.key}>
            <p className="question"><span className="nombre_pregunta">{item.nombre}</span> <br /> {item.organizacion}</p>
            <p className="question"><strong>{item.pregunta}</strong></p>
            <p className="botones">
            <button className="acceptButton" onClick={()=>{
                    aceptQuestion(item);
                  }}>ACEPTAR</button>
            </p>
          </div>) : null;
        })}
            </Col>
          </Row>
          <br /><br /><br />
        </Col>
        
      </Row>
    </>)
  
}

export default Admin;