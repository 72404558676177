import React, {useParams, useContext, useRef} from 'react';
import {} from 'react-router-dom';
import { Row, Col, Form, Input, Button, Checkbox } from 'antd';

import { Auth } from "../../utils/AuthContext";
import app from '../../utils/firebaseConfig';

import '../../assets/css/sala.css';
import avatar from '../../assets/images/temporal/500x400.png';

const { TextArea } = Input;

const layout = {
  wrapperCol: {
    span: 24,
  },
};


const Live = (props) =>{
  const {url, comentarios, webinar } = props;

  const { datos } = useContext(Auth);

  const ref_form_pregunta = useRef();

  
    let visualizar_preguntas = comentarios.filter((pre) => {
        if(pre.status === 'aceptada'){
          return true;
        }else{
          return false;
        }
    }).map((pre)=>{
      return(
        <div>
          <p className="texto-preguntas">
          <span>{pre.pregunta}</span><br />
            <strong><span>{pre.nombre}</span></strong><br />
            <strong><span>{pre.empresa}</span></strong>
            </p>
        </div>
      );
    });
  

  

  const onFinish = values => {
    console.log(datos);
    let pregunta = {
      empresa: datos.empresa,
      nombre: datos.nombre+' '+datos.ap_paterno+' '+datos.ap_materno,
      pregunta: values.pregunta,
      status: 'pendiente'
    }

    app.escribir(webinar+'/preguntas-publico',undefined, pregunta, () => {
      alert('pregunta enviada');
      
      ref_form_pregunta.current.resetFields();
    });
  };

  const onFinishFailed = errorInfo => {
    
  };

  return(
   <Row justify="center" align="" >
    <Col span={14}>
    <iframe width="100%" height="500" src={url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </Col>
    <Col span={6}>
      <Row justify="center">
        <Col span={4} >
          <img alt="live" src={avatar} style={{ width:'100%' }} />
        </Col>
        <Col span={18} className="header-preguntas">
          <p>PREGUNTA AL PONENTE</p>
        </Col>
        <Col span={22} className="ver-preguntas" >
          {visualizar_preguntas}
        </Col>
        <Col span={22} style={{ backgroundColor: '#eb4b1c', paddingTop:'10px'}}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            pregunta:'',
            remember: true,
          }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            ref={ref_form_pregunta}
          >
            <Row justify="center">
              <Col span={22} offset={1} >
                <Form.Item
                
                  name="pregunta"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor escribe una pregunta',
                    },
                  ]}
                >
                  <TextArea  />
                </Form.Item>
              </Col>
              <Col span={22} offset={1} style={{textAlign:'right'}}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" className="btn-enviar">
                    Enviar
                  </Button>
                </Form.Item>
              </Col>
              <Col span={17} style={{ padding:'5px 0px'}}>
                <p className="sujeto">* Todas las preguntas están sujetas a aprobación</p>
              </Col>
              </Row>
        </Form>
      </Col>
    </Row>
    </Col>
   </Row>
  );
}

export default Live;