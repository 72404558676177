import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import {useLocation, BrowserRouter} from 'react-router-dom';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import { HashScroll } from "react-hash-scroll";

import logo_1 from '../../assets/images/sitio/Logos/Sinergia_Patrocinios_BEYOND.jpg';
import logo_2 from '../../assets/images/sitio/Logos/Sinergia_Patrocinios_SIEMENS.jpg';
import logo_3 from '../../assets/images/sitio/Logos/Sinergia_Patrocinios_SUME.jpg';
import logo_4 from '../../assets/images/sitio/Logos/Sinergia_Patrocinios_TIP.jpg';
import logo_5 from '../../assets/images/sitio/Logos/Sinergia_Patrocinios_WGBC.jpg';

import '../../assets/css/evento.css';

import Contador from  './Contador';
import CarouselPonentes from './CarouselPonentes';
import Agenda from './Agenda';
import Whatsapp from '../master/Whatsapp';
import { Element } from 'react-scroll';

import logo_banner from '../../assets/images/sitio/Logos/Sinergia_LogoBannerPrincipal.png';

import lgo_fecha from '../../assets/images/sitio/Iconos/Sinergia_Calendario.png';
import lgo_speak from '../../assets/images/sitio/Iconos/Sinergia_Speaker.png';
import lgo_lugar from '../../assets/images/sitio/Iconos/Sinergia_Gps.png';
import img_registro_btn from '../../assets/images/sitio/Botones/Sinergia_Boton_Registrate.png';


import img_registrate_cintillo from '../../assets/images/sitio/Botones/Sinergia_Boton_Comprar.png';

import precio_paquete from '../../assets/images/sitio/Banners/Sinergia_EntradaGeneral.png';
import precio_paquete_movil from '../../assets/images/sitio/Banners/Sinergia_EntradaGeneral_movil.png';

import AcercaDe from './AcercaDe';
import CarouselPanel from './CarouselPanel';

const Evento = (props) =>{

  const {idioma} = props;

  return(
    <>
    {/*<Whatsapp idioma={idioma} />*/}
    <Row justify="center" align="middle">
      <Col xs={0} md={24} className="principal">
        <Row>
          <Col span={12} offset={6}><br /><br /><br />
            <img src={logo_banner} width="100%" />
          </Col>
          <Col span={12} offset={6}  style={{display:'non'}}>
            <Row justify="space-between">
              <Col span={7}>
                <Row align="middle">
                  <Col span={8}>
                    <img src={lgo_fecha} style={{width:'40px'}} />
                  </Col>
                  <Col span={16}>
                    <p className='fecha_banner'>
                      23 DE<br />
                      NOVIEMBRE
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Row align="middle">
                  <Col span={8}>
                    <img src={lgo_speak} style={{width:'40px'}} />
                  </Col>
                  <Col span={16}>
                    <p className='fecha_banner'>
                      +25<br />
                      SPEAKERS
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Row align="middle">
                  <Col span={8}>
                    <img src={lgo_lugar} style={{width:'40px'}} />
                  </Col>
                  <Col span={16}>
                    <p className='fecha_banner'>
                      CORPORATIVO SIEMENS <br />CDMX
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={4} offset={10}>
            <br /><br /><br />
            
            <a href="/registro"><img src={img_registro_btn} width="100%" /></a>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={0} className="principal_movil">
        <Row>
          <Col span={16} offset={4}>
          
          <img src={logo_banner} width="100%" />
          </Col>
        </Row>
      </Col>
      </Row>
      <Row className='contenedor_description'>
        <Col xs={{span:20, offset:2}}>
        <p className='texto_descripcion'>Primer Encuentro de Energía, Electromovilidad y Bienes Raíces Sustentables
        <br />
        <span className='color_amarillo'>Uniendo las Industrias • Acelerando la descarbonización</span>
        </p>
        <p className='subtexto_descripcion'>
        Tiene como objetivo difundir y comunicar acerca de la importancia de la industria de descarbonización, energía y movilidad eléctrica a los diferentes sectores industriales principalmente el linmobiliario, de la construcción y certificación LEED. Además de compartir temas de energía, descarbonización, sostenibilidad y sus aplicaciones con el sector Inmobiliario.
        </p>
        </Col>
        <Col xs={{span:16, offset:4}}>
        <Contador dateTarget="11/23/2023 09:00:00 AM" />
        </Col>
      </Row>
      
      <Row style={{display:'non'}}>
        <Col id="acerca-de" name="#acerca-de" spen={24}>
          <AcercaDe idioma={idioma} />
        </Col>
      </Row>
      <Row id="registro"  style={{display:'non'}}>
      <Element name="#entradas" />
        <Col xs={{span:0, offset:0}} md={{span:24, offset:0}}>
          <Link to="/registro"><img src={precio_paquete} style={{width:'100%'}} /></Link>
        </Col>
        <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
          <Link to="/#"><img src={precio_paquete_movil} style={{width:'100%'}} /></Link>
        </Col>
      </Row>
    <Element name="#programa" />
      <Row justify="center" align="middle"  style={{display:'non'}}>
      <Col span={24} className="" id="programa">
      <HashScroll hash="#programa" position="center">

        <Agenda idioma={idioma}/>
        </HashScroll>
      </Col>
      </Row>
      
      <Element name="#speakers" />
      <Row id="speakers" justify="center" align="middle" style={{display:'non'}}>
      
        <Col span={24}>
          
          <CarouselPonentes idioma={idioma}/>
        </Col>
      </Row>
     <Row align="middle" justify="center">
      <Col span={20}>
        <br /><br />
        <p className='titulo_patrocinadores'>NUESTROS PATROCINADORES</p>
      </Col>
      <Element name="#sponsors" />
      
      <Col id="sponsors" span={20}>
        <Row  align="middle" justify="center">
          <Col xs={8} md={4}>
            <img src={logo_3} style={{width:'100%'}} />
          </Col>
          <Col xs={8} md={4}>
            <img src={logo_2} style={{width:'100%'}} />
          </Col>
          <Col xs={8} md={4}>
            <img src={logo_1} style={{width:'100%'}} />
          </Col>
          <Col xs={8} md={4}>
            <img src={logo_4} style={{width:'100%'}} />
          </Col>
          <Col xs={8} md={4}>
            <img src={logo_5} style={{width:'100%'}} />
          </Col>
        </Row>
        <br />
      </Col>
     </Row>
      <Row justify="center" align="middle" className='contenedor_cintillo_abajo'>
        <Col xs={{span:22}} md={{span:14}}>
          <p className='text_cintillo'>
            Conoce la importancia de la industria de descarbonización, energía y movilidad eléctrica a los diferentes sectores industriales</p>
        </Col>
        <Col xs={{span:20}} md={{span:6}}>
          <Link to="/registro" ><img style={{width:'200px'}} src={img_registrate_cintillo}/></Link>
        </Col>
        </Row>
        
    </>
  );
}

export default Evento;