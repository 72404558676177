import React, { useState, useEffect } from 'react';
import { Row, Col, Carousel } from 'antd';
import '../../assets/css/caracteristicas.css';

import img_generacion from '../../assets/images/sitio/Iconos/Sinergia_Temas_GeneracionEnergia.png';
import img_movilidad from '../../assets/images/sitio/Iconos/Sinergia_Temas_Descarbonizacion.png';
import img_infraestructura from '../../assets/images/sitio/Iconos/Sinergia_Temas_Infraestructura.png';
import img_descarbonizacion from '../../assets/images/sitio/Iconos/Sinergia_Temas_Descarbonizacion.png';
import img_mercado from '../../assets/images/sitio/Iconos/Sinergia_Temas_Mercado.png';
import img_bms from '../../assets/images/sitio/Iconos/Sinergia_Temas_BMS.png';


const AcercaDe = (props) => {
    const { idioma } = props;
    return (
        <>
        
            <Row align='middle' style={{padding:'3%'}}>
                <Col xs={{span:20, offset:2}} md={{span:20, offset:2}}>
                    <p className='titulo_acercade'><strong>¿Porqué asistir?</strong></p>
                        <p className="texto_acercade">
                        Para expandir tus conocimientos sobre la transición a la electrificación de procesos de nuestra vida cotidiana para la disminución de CO2 a través de las palabras de expertos en:
                        </p>
                </Col>
                <Col xs={{span:22, offset:1}} md={{span:20, offset:2}}>
                    <Row justify='space-around'>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_generacion} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>GENERACIÓN, ALMACENAMIENTO, TRANSMISIÓN Y DISTRIBUCIÓN DE LA ENERGÍA </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_movilidad} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>MOVILIDAD ELÉCTRICA</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_infraestructura} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>INFRAESTRUCTURA DE CARGA ELÉCTRICA</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_descarbonizacion} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>DESCARBONIZACIÓN</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_mercado} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>MERCADO ELÉCTRICO MAYORISTA</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{span:12}} md={{span:8}} style={{padding:'1%'}}>
                            <Row align="middle">
                                <Col xs={{span:5, offset:0}}>
                                    <img src={img_bms} className='img_acercade_icono' />
                                </Col>
                                <Col xs={{span:17, offset:1}}>
                                    <p className='mini_tutluo_acercade'>BMS</p>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                </Col>
                <Col span={24}>
                    <br /><br />
                </Col>
            </Row>
        </>
    )
}
export default AcercaDe;