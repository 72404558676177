import React, { useState } from 'react';
import { Row, Col } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/panel.css';

import panelista_1 from '../../assets/images/temporal/500x400.png';
import { Element } from 'react-scroll';


const Paneles = (props) => {

  const {idioma} = props;

    let panelistas = [
        {nuero: 1, hash:"#evolucion-del-diseno", nombre:'La Evolución del Diseño de Iluminación en Mexico', nombre_en:'The Evolution of the Lighting Design in Mexico', descripcion:[<>Tendremos una interesante plática acerca de la evolución y la transformación que ha tenido el Diseño de Iluminación en México, participando como invitados especiales cuatro destacados Diseñadores de Iluminación de nuestro país.</>], 
        descripcion_en:[<>We will have an interesting talk about the evolution and transformation that Lighting Design has had in Mexico, participating as special guests four outstanding Lighting Designers of our country.</>],
        panelistas:[{nombre:'María de los Ángeles Escobedo',empresa:'Unicorp Lighting + Design & Architectural Lighting Solutions',
        img:panelista_1,bandera:'',
        texto:[<>Licenciatura en Diseño Gráfico (U. Iberoamericana), Postgrado Metodología de Diseño, (U. Elisava Barcelona), Administración de Empresas (IPADE) y Diplomado en Diseño y Construcción Sostenibles (U. Iberoamericana). Certificación Lighting Credential (LC) por National Council on Qualifications for the Lighting Professions (NCQLP) en USA y Acreditación Profesional LEED AP por el United States Green Building Council (USGBC).<br /><br />
            Más de 30 años de experiencia, fundadora y Directora de UNICORP LIGHTING+DESIGN y ARCHITECTURAL LIGHTING SOLUTIONS, dedicadas al diseño, integración e implementación de Sistemas de Iluminación Arquitectónica, orientadas a la Iluminación Sustentable. Reconocimientos internacionales a sus proyectos por parte de la IES, siendo los principales: AWARD OF EXCELLENCE (Proyecto LEED Oro), AWARD OF MERIT (Proyecto LEED Platinum) y AWARD OF MERIT (Proyecto LEED ORO). Miembro de la IES desde 1993 y del United States Green Building Council (USGBC) desde 2007.</>],
        texto_en:[<>Degree in Graphic Design (U. Iberoamericana), Postgraduate Design Methodology, (U. Elisava Barcelona), Business Administration (IPADE) and Diploma in Sustainable Design and Construction (U. Iberoamericana). Lighting Credential Certification (LC) by National Council on Qualifications for the Lighting Professions (NCQLP) in USA and Professional Accreditation LEED AP by the United States Green Building Council (USGBC).<br /><br />
            More than 30 years of experience, founder and Director of UNICORP LIGHTING+DESIGN and ARCHITECTURAL LIGHTING SOLUTIONS, dedicated to the design, integration and implementation of Architectural Lighting Systems, oriented to Sustainable Lighting.<br /><br />
            International recognition of her projects by the IES, among those that stand out: AWARD OF EXCELLENCE (LEED Gold Project), AWARD OF MERIT (LEED Platinum Project) and AWARD OF MERIT (LEED GOLD Project). Member of the IES since 1993 and of the United States Green Building Council (USGBC) since 2007.</>]},
            ]},
    ]

    const ver_panelistas = panelistas.filter((i)=>
    {
        if(i.nuero === 1){
        return true
    }else
        {return false}
    }
    ).map((item) => {
        return(
            <>
            <Element name={item.hash} />
            <Col span={20} className='contenedor_panel'>
                <p className='nombre_conf_panel'>{idioma === 'es' ? item.nombre : item.nombre_en}</p>
                <p className='desc_conf_panel'>{idioma === 'es' ? item.descripcion : item.descripcion_en}</p>
                {item.panelistas.map((persona) =>{
                    return(
                        <>
                        <Row justify='center' className='contenedor_persona_panel'>
                            <Col xs={12} lg={6} style={{textAlign:'center'}}>
                                <img src={persona.img} className='img_parsona_panel' />
                            </Col>
                            <Col xs={22} lg={18}>
                                <p className='nombre_persona_panel'>{persona.nombre} <img src={persona.bandera} className='img_bandera_panel' /></p>
                                <p className='empresa_persona_panel'>{persona.empresa}</p>
                                <p className='descipcion_persona_panel'>{idioma === 'es' ? persona.texto : persona.texto_en}</p>
                            </Col>
                        </Row>
                        </>
                    )
                })

                }
            </Col>
            </>
        )
    })


    const ver_panelistas_2 = panelistas.filter((i)=>
    {
        if(i.nuero === 2){
        return true
    }else
        {return false}
    }
    ).map((item) => {
        return(
            <>
            <Element name={item.hash} />
            <Col span={20} className='contenedor_panel'>
                <p className='nombre_conf_panel'>{idioma === 'es' ? item.nombre : item.nombre_en}</p>
                <p className='desc_conf_panel'>{idioma === 'es' ? item.descripcion : item.descripcion_en}</p>
                {item.panelistas.map((persona) =>{
                    return(
                        <>
                        <Row justify='center' className='contenedor_persona_panel'>
                            <Col xs={12} lg={6} style={{textAlign:'center'}}>
                                <img src={persona.img} className='img_parsona_panel' />
                            </Col>
                            <Col xs={22} lg={18}>
                                <p className='nombre_persona_panel'>{persona.nombre} <img src={persona.bandera} className='img_bandera_panel' /></p>
                                <p className='empresa_persona_panel'>{persona.empresa}</p>
                                <p className='descipcion_persona_panel'>{idioma === 'es' ? persona.texto : persona.texto_en}</p>
                            </Col>
                        </Row>
                        </>
                    )
                })

                }
            </Col>
            </>
        )
    })

    return (
      <>
      <Row justify="center" align="middle" className=''>
       
          <Col span={24}>
            <br /><br /><br /><br />
          </Col>

        <Col span={24}>
            <Element name='#evolucion-del-diseno' />
            <Row justify='center'>
                {ver_panelistas}
            </Row>
        </Col>
        <Element name="#presente-y-futuro" />
        <Col span={24}>
            <Row justify='center'>
                {ver_panelistas_2}
            </Row>
        </Col>
      </Row>
    </>
    );

};

export default Paneles;