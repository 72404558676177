import React, {useState, useEffect} from 'react';
import { Row, Col } from 'antd';

// Milliseconds for the calculations
const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = MILLISECONDS_OF_A_SECOND * 60;
const MILLISECONDS_OF_A_HOUR = MILLISECONDS_OF_A_MINUTE * 60;
const MILLISECONDS_OF_A_DAY = MILLISECONDS_OF_A_HOUR * 24

const Contador = (props) =>{

  const [mostrarMensaje, setMostrarMensaje] = useState(false);
  const {dateTarget} = props;
  const [segundos, setSegundos] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND));
  const [minutos, setMinutos] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE));
  const [horas, setHoras] = useState(Math.floor(((new Date(dateTarget) - (new Date())) % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR));
  const [dias, setDias] = useState(Math.floor(((new Date(dateTarget) - (new Date())) / MILLISECONDS_OF_A_DAY)));

  const updateCountdown = () => {
    // Calcs
    const NOW = new Date();
    const DURATION = new Date(dateTarget) - NOW;

    if(DURATION <= 0){
      setMostrarMensaje(true);
    }

    setDias(Math.floor(DURATION / MILLISECONDS_OF_A_DAY));
    setHoras(Math.floor((DURATION % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR));
    setMinutos(Math.floor((DURATION % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE));
    setSegundos(Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND));

}


useEffect( () => {
  setInterval(updateCountdown, MILLISECONDS_OF_A_SECOND);
}, []);

  return(
    <Row justify="center" className="contenedor_contador">

      { mostrarMensaje ? (
        <Col span={20} offset={1} style={{textAlign:'center'}}>
          <p className="mensaje-contador">¡Ya estamos en vivo!</p>
        </Col>
      )
    :
    (
      <>
<Col xs={5} md={3}>
                <p className="contador">
                  
  <span className="numero" id="days">{dias}</span><br />
  <span className="fecha">DÍAS</span>
                </p>
              </Col>
              <Col xs={5} sm={5} md={4}>
                <p className="contador">
                  
      <span className="numero" id="hours">{horas}</span><br />
      <span className="fecha">HRS</span>
                </p>
              </Col>
              <Col xs={5} sm={5} md={4}>
                <p className="contador">
  <span className="numero" id="minutes">{minutos}</span><br />
                  <span className="fecha">MIN</span>
                </p>
              </Col>
              <Col xs={5} sm={5} md={4}>
                <p className="contador">
                  
  <span className="numero" id="seconds">{segundos}</span><br />
  <span className="fecha">SEG</span>
                </p>
              </Col>
              </>
    )
    }

              
            </Row>
  );
}

export default Contador;
