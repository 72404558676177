import React from 'react';
import {} from 'react-router-dom';
import { Row, Col } from 'antd';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';
import Live from './Live';
import {Preguntas} from './Preguntas'; 
import '../../assets/css/sala.css';

const Sala = (props) =>{
  return(
   <div className="cuerpo">
    <Row justify="center" align="">
      <Col span={20}>
        <CarouselPatrocinadores num_items={6} />
        <br/><br/>
      </Col>
      <Col span={24}>
        <Live url="prueba" />
      </Col>
      <Col span={24}>
        <Preguntas />
      </Col>
    </Row>
   </div>
  );
}

export default Sala;