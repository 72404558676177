import React, { useState } from 'react';
import { Row, Col, Carousel, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons';
import '../../assets/css/agenda.css';

import miercoles from '../../assets/images/sitio/Botones/Sinergia_Boton_23noviembre.png';
import miercoles_hover from '../../assets/images/sitio/Botones/Sinergia_Boton_23noviembre_scroll.png';
import jueves from '../../assets/images/sitio/Botones/Sinergia_Boton_24noviembre.png';
import jueves_hover from '../../assets/images/sitio/Botones/Sinergia_Boton_24noviembre_scroll.png';

import Slider from 'react-slick';

const dias = [
  { hora: '08:00', tipo: 1, actividad:'REGISTRO', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  </>]},
  { hora: '09:00', tipo: 1, actividad:'BIENVENIDA', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
  <span className='color_amarillo aileron_bold'>Alicia Silva</span> • Presidenta de SUMe<br />
  </p>
  </>]},
  { hora: '09:15', tipo: 1, actividad:'SESIÓN 1:', titulo: 'SMART CITIES: TENDENCIAS', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • Smart cities y la energía<br />
    • El edificio Inteligente como generador de energía<br />
    • Cómo incrementar los ingresos de un portafolio inmobiliario con energía<br />
    • Tecnologías en las Smart Cities
    <br /><br />
    Moderador: <br />
    <span className='color_amarillo aileron_bold'>Alicia Silva</span> SUMe <br /><br />
    Speakers: <br />
    <span className='color_amarillo aileron_bold'>Leonardo Beltran</span> Asesor en energia gobierno <br />
    <span className='color_amarillo aileron_bold'>Ruth Corona</span> JLL<br />
    <span className='color_amarillo aileron_bold'>Claudia Velazquez</span> SOFTEC MEXICO
  </p>
  </>]},
  { hora: '10:00', tipo: 1, actividad:'SESIÓN 2:', titulo: 'INFRAESTRUCTURA DE CARGA (EV): Oportunidades de Negocio', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • La implementacion de infraestructura de carga en desarrollos inmobiliarios<br />
    • Tipos de carga para autos electricos<br />
    • Modelos de negocio en la electromovilidad
    <br /><br />
    Moderador: <br />
    <span className='color_amarillo aileron_bold'>Diego Gonzalez</span> BEYOND ENERGY<br /><br />
    Speakers: <br />
    <span className='color_amarillo aileron_bold'>Africa Rubio</span> CUSHMAN & WAKEFIELD<br />
    <span className='color_amarillo aileron_bold'>David Cacerez</span> LIVERPOOL <br />
    <span className='color_amarillo aileron_bold'>Andres Lajous</span> SEMOVI CDMX
  </p>
  </>]},
  { hora: '10:45', tipo: 1, actividad:'SESIÓN 3:', titulo: 'ELECTROMOVILIDAD: Transportación sostenible', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    <span className='color_amarillo aileron_bold'></span> • Autos Sustentables: El mercado actual<br />
    <span className='color_amarillo aileron_bold'></span> • Electromovilidad y logística de última milla<br />
    <span className='color_amarillo aileron_bold'></span> • Electromovilidad y transporte masivo
    <br /><br />
    Moderador: <br />
    <span className='color_amarillo aileron_bold'>Horacio Ramos</span> SIEMENS<br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Javier Amozurrutia</span> MAZMOBI<br />
    <span className='color_amarillo aileron_bold'>David Omar Garcia Valles</span> LIVERPOOL<br />
    <span className='color_amarillo aileron_bold'>Fernando Castillo</span> BYD
  </p>
  </>]},
  { hora: '11:30', tipo: 1, actividad:'Coffe Break / Networking', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<></>]},
  { hora: '12:00', tipo: 1, actividad:'SESIÓN 4:', titulo: 'INFRAESTRUCTURA ENERGÉTICA Y DESCARBÓNIZACIÓN EN EDIFICIOS', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • Compromisos Net Zero<br />
    • Implementando una visión de descarbonización<br />
    • La digitalización en los edificios y su impacto en la huella de carbono
    <br /><br />
    Moderador:<br />
    <span className='color_amarillo aileron_bold'>Edgar Runnebaum</span> SIEMENS
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Felipe Flores </span>TORRE MAYOR/DIANA<br />
    <span className='color_amarillo aileron_bold'>Enrique Motola </span>THREE
  </p>
  </>]},
  { hora: '12:45', tipo: 1, actividad:'SESIÓN 5:', titulo: 'MERCADOS DE CARBÓN', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • OFFSET: compensaciones y neutralidad del carbono<br />
    • Bonos de carbono y sus aplicaciones<br />
    • Como obtener datos de carbono de alta calidad
    <br /><br />
    Moderador:<br />
    <span className='color_amarillo aileron_bold'>Juan Pablo Chargoy</span> CADIS
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Eduardo Piquero</span> MEXICO 2 <br />
    <span className='color_amarillo aileron_bold'>Alicia Acosta</span> TIP<br />
    <span className='color_amarillo aileron_bold'>Elizabeth Mosqueda</span> CARBON TRUST <br />
    <span className='color_amarillo aileron_bold'>Ashley Albarrán</span> HSBC
  </p>
  </>]},
  { hora: '13:30', tipo: 1, actividad:'COMIDA', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<></>]},
  { hora: '19:00', tipo: 1, actividad:'', titulo: 'CÓCTEL PATROCINADO', nombre: '', lugar: '', extra: '', dia: '0', 
  descripcion:[<></>]},


  { hora: '14:30', tipo: 1, actividad:'SESIÓN 6:', titulo: 'DIFERENTES MERCADOS DE GENERACIÓN Y CONSUMO DE ENERGÍA Y SU MARCO LEGAL', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • Generación distribuida<br />
    • Generación centralizada<br />
    • Regulación energética
    <br /><br />
    Moderador:<br />
    <span className='color_amarillo aileron_bold'>Tania Mijares</span> BUSTAMANTE Y FREYRE 
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Miriam Grunstein</span> BRILLIANT ENERGY CONSULTING<br />
    <span className='color_amarillo aileron_bold'>Federico Lopez de Alva </span> CFE<br />
    <span className='color_amarillo aileron_bold'>Jose Buganza</span> ENEGENCE
  </p>
  </>]},
  { hora: '15:15', tipo: 1, actividad:'MERCADO ELÉCTRICO MAYORISTA', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • Suministrador autorizado<br />
    • Grandes consumidores de energía
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Alejandro de Keijser</span> DEACERO
  </p>
  </>]},
  { hora: '16:00', tipo: 1, actividad:'BREAK / NETWORKING', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<></>]},
  { hora: '16:30', tipo: 1, actividad:'SESIÓN 7:', titulo: 'ENERGÍA: OPCIONES A FUTURO', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • El poder del almacenamiento de energía<br />
    • El futuro de las baterías<br />
    • Sistemas desconectados de la red <br />
    • El futuro energético 
    <br /><br />
    Moderador:<br />
    <span className='color_amarillo aileron_bold'>Guillermo Gutiérrez</span> SIEMENS
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Joao Resende</span> SIEMENS <br />
    <span className='color_amarillo aileron_bold'>Emanuel Moctezuma</span> AES 
  </p>
  </>]},
  { hora: '14:15', tipo: 1, actividad:'COMIDA', titulo: '', nombre: '', lugar: '', extra: '', dia: '0', 
  descripcion:[<></>]},
  { hora: '17:15', tipo: 1, actividad:'SESIÓN 8:', titulo: 'ENERGÍAS RENOVABLES', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
    • El desarrollo inmobiliario como motor de generación de energía verde<br />
    • Sistemas híbridos de generación<br />
    • El Futuro de la generación por Biomasa, Eólica, Solar, etc.
    <br /><br />
    Speakers:<br />
    <span className='color_amarillo aileron_bold'>Joaquina Niembro</span> UNIVERSIDAD PANAMERICANA<br />
    <span className='color_amarillo aileron_bold'>Mariana Guerrero</span> SUNPOWER<br />
    <span className='color_amarillo aileron_bold'>Rommel González</span> BEYOND
  </p>
  </>]},
  { hora: '18:00', tipo: 1, actividad:'DESPEDIDA', titulo: '', nombre: '', lugar: '', extra: '', dia: '1', 
  descripcion:[<>
  <p className='descripcion_agenda'>
  <span className='color_amarillo aileron_bold'>Alicia Silva</span> • Presidenta de SUMe
  </p>
  </>]},
]
const Agenda = (props) => {
  const [ver_dia, setVerDia] = useState(1);
  const { idioma } = props;

  let dias_vista = dias.filter(dia => {
    if (dia.dia == ver_dia) {
      return true;
    }
    return false;
  });

  let ver_actividades = dias_vista.map((seccion, index) => {
    if (seccion.tipo == 1) {
      return (
        <>
            <Row className="" justify='center' align='top'>
            <Col xs={{span:6, offset:0}} md={{span:4, offset:0}} >
                <p className='hora_agenda'><strong>{seccion.hora}</strong></p>
              </Col>  
              <Col xs={{span:17, offset:0}} md={{span:19, offset:1}} className=''>
                <div >
                  <p className='actividad_agenda'>
                    <strong>{seccion.actividad}<br /> {seccion.titulo}</strong></p>
                    {seccion.descripcion}
                </div>
              </Col>
            </Row>
        </>
      )
    } else if (seccion.tipo == 2) {
      return (
        <>
        </>
      )
    }
  })


  return (
    <>
      <Row justify='center' className='contenedor_agenda_back'>
        <Col xs={24} md={24} className="">
          <p className='titulo_seccion color_blanco'>PROGRAMA<br />
          23 DE NOVIEMBRE 2023</p>
        </Col>
        <Col xs={22} md={22} className=''>
          <Row justify="center" align="middle">
            <Col span={24}>
              
            </Col>
           {/* <Col xs={{span:12, offset:0}} md={{span:7, offset:0}} onClick={() => { setVerDia(1) }} className="ponter_select">
              <img src={ver_dia === 1 ? miercoles_hover : miercoles} style={{ width: '100%' }} />
            </Col>
            <Col xs={{span:12, offset:0}} md={{span:7, offset:1}} onClick={() => { setVerDia(2) }} className="ponter_select">
                <img src={ver_dia === 2 ? jueves_hover : jueves} style={{ width: '100%' }} />
  </Col>*/}
          </Row>
          <Row justify="center" align="middle">
            <Col xs={20} lg={20} className="divPrograma-" style={{ height: '100%' }}>
              
            {ver_actividades}
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

export default Agenda;