import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { Row, Col } from "antd";
import ExpoMovil from "./ExpoMovil";
import ExpoWeb from "./ExpoWeb";

import '../../assets/css/botones.css';

const Expo = (props) => {
  const { idioma } = props;

  return (
    <>
      <Row>
        <Col xs={24} md={0}>

        <br /><br /><br /><br />
        <br /><br /><br /><br />
        <div className='btn_confetencia_div'>
        <a className='link_btn_confe' href="/on-demand">
          {idioma === 'es' ? 'SALÓN CONFERENCIAS' : 'CONFERENCES HALL'}<span class="first"></span>
        </a>
      </div>
        </Col>
        <Col xs={24} md={0}>
       
<ExpoMovil idioma={idioma}  />
          
        </Col>
        <Col xs={0} md={24}>
          <ExpoWeb idioma={idioma} />
        </Col>
      </Row>
    </>
  );
};

export default Expo;
