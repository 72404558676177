import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import CarouselPatrocinadores from '../evento/CarouselPatrocinadores';

import { AiFillFacebook, AiOutlineTwitter, AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";

import btn_mail from '../../assets/images/2023/botones/ies_boton_Correo.png';
import btn_mail_hover from '../../assets/images/2023/botones/ies_boton_Correo_scroll.png';
import btn_mail_en from '../../assets/images/2023/botones/ies_boton_Correo.png';
import btn_mail_hover_en from '../../assets/images/2023/botones/ies_boton_Correo_scroll.png';
import hasthag from '../../../src/assets/images/sitio/Iconos/Sinergia_Hashtag.png';

import btn_wsp from '../../assets/images/2023/botones/ies_boton_whats_scroll.png';
import btn_wsp_hover from '../../assets/images/2023/botones/ies_boton_whats.png';
import btn_wsp_en from '../../assets/images/2023/botones/ies_boton_whats_scroll.png';
import btn_wsp_hover_en from '../../assets/images/2023/botones/ies_boton_whats.png';

import logo_footer_1 from '../../../src/assets/images/sitio/Logos/Sinergia_LogoFooter.png';


import titulo_patrocinadores from '../../../src/assets/images/2023/titulos/ies_nuestrosPatrocinadores.png';
import titulo_patrocinadores_en from '../../../src/assets/images/2023/titulos/Titulos_ENG/ies_nuestrosPatrocinadores_ENG.png';

import titulo_masinfo from '../../../src/assets/images/2023/titulos/ies_MasInfo.png';
import titulo_masinfo_en from '../../../src/assets/images/2023/titulos/Titulos_ENG/ies_MasInfo_ENG.png';
import { Element } from 'react-scroll';


const Footer = (props) => {
  let location = useLocation();
  console.log(location);
  const {idioma} = props;
  const [hover,setHover]=useState('0');

  return(
    <>
    
    {location.pathname === '/' || location.pathname === '/registro' || location.pathname === '/registro/1' || location.pathname === '/registro/2' || location.pathname === '/registro/3' || location.pathname === '/registro/4' || location.pathname === '/registro/5' ?
      <Row justify="center" align="middle"  style={{display:'none'}}>
          <Col  xs={{span:20}} md={{span:18}}>
              <Link to="/salon-conferencias"><img src={idioma === 'es' ? titulo_patrocinadores : titulo_patrocinadores_en } style={{width:'100%'}} /></Link>
            </Col>
          
          <Col xs={22} lg={22}>
          <Element name="#patrocinadores" />
          <CarouselPatrocinadores num_items={4} />
            <br />
          </Col>
        </Row>
        :
        <>
        </>
        }
        
        {location.pathname === '/' || location.pathname === '/registro' ?
        <Row align="middle" style={{display:'none'}}>
    <Col  xs={{span:20,offset:2}} md={{span:12,offset:6}}>
              <img src={idioma === 'es' ? titulo_masinfo : titulo_masinfo_en } style={{width:'100%'}} />
            </Col>
          <Col xs={{span:16,offset:4}} md={{span:7, offset:4}}>
          <a href="mailto:seminario2021@iesmexico.org" target="_blank">
          <img src={idioma === 'es' ? (hover === '1' ? btn_mail_hover : btn_mail) : (hover === '1' ? btn_mail_hover_en : btn_mail_en)} onMouseEnter={()=>setHover('1')} onMouseLeave={()=>setHover('0')} className="btn_footer_mail" />
          </a>
          </Col>
          <Col xs={{span:16,offset:4}} md={{span:7, offset:2}}>
          <a href="https://api.whatsapp.com/send?phone=5518458117&text=Hola" target="_blank">
          <img src={idioma === 'es' ? (hover === '2' ? btn_wsp_hover : btn_wsp) : (hover === '2' ? btn_wsp_hover_en : btn_wsp_en)} onMouseEnter={()=>setHover('2')} onMouseLeave={()=>setHover('0')} className="btn_footer_mail" />
          </a>
          </Col>
          <Col span={24}>
            <br /><br />
          </Col>
    </Row>
    :
    <>
    </>
}
<Row>
        </Row>
    <Row justify='space-around' align="middle" className="contenedor_footer">
      <Col xs={10} lg={6}>
      <a href="https://www.facebook.com/SUMeSustentabilidadParaMexico" target="_blank"><AiFillFacebook className="redes" /></a>
      <a href="https://www.instagram.com/sume_mex/" target='_blank'><AiOutlineInstagram className="redes" /></a>
      </Col>
      <Col xs={0} lg={6}>
      
      <img src={logo_footer_1} className="logo_footer"/>
      
      </Col>
      <Col xs={10} lg={6}>
      <img src={hasthag} className="logo_footer"/>
      </Col>
    </Row>
    
    </>
  );
}

export default Footer;