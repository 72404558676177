import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Upload,
  Checkbox,
  Button, notification, Spin
} from 'antd';
import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import RegistroCompleto from './RegistroCompleto';

import { useMatch, useNavigate, useParams } from "react-router-dom";

import { mail_registro_confirmacion, codigo, paquete, SERVER_URL } from '../../utils/ConexionServidor';
import banner_registro from '../../assets/images/sitio/Banners/Sinergia_BannerRegistro.jpg';
import banner_registro_movil from '../../assets/images/sitio/Banners/Sinergia_BannerRegistro_movil.jpg';
import img_paquete from '../../assets/images/sitio/Banners/Sinergia_Entrada_Registro.png';

import { CaretLeftOutlined, CaretRightOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import Whatsapp from "../master/Whatsapp";


const { Option } = Select;

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

let url_file = "";

const Registro = (props) => {

  console.log(props);

  const [error, seterror] = useState("");
  const [otro_medio, setOtroMedio] = useState(false);
  const [datos_registro, setDatosRegistro] = useState([]);
  const [confirmacion, setConfirmacion] = useState(false);
  const [paquete_elegido, setPaqueteElegido] = useState(0);
  const [ver_total, setTotal] = useState(0);
  const [select_imagen, setSelectImagen] = useState([false, false, false, false]);
  const [porcentaje_codigo, setPorcentajeCodigo] = useState(0);
  const [paquetes, setPaquetes] = useState([]);
  const [facturar, setFacturar] = useState(false);
  const [cargar_pantalla, setCargarPantalla] = useState(false);
  const { idioma, setMostrarLogin, cerrarMenu } = props;
  const nom_evento = "2023";
  const [ver_hover, setVerHover] = useState(false);

  const [valor_codigo, setValorCodigo] = useState(0);

  const ref_inputxs = useRef(null);
  const ref_inputmd = useRef(null);

  //const [form] = Form.useForm();

  //const ref_form = React.createRef();
  const ref_recaptcha = React.createRef();

  let normFile = (e) => {

    //url_file = e.file.response.data;

    if (e.file.response !== undefined) {
      console.log('Upload event:', e.file.response.data);
      url_file = e.file.response.data;
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const cambiarHoverImagen = (index) => {
    let arreglo = [];
    for (let i = 0; i < 4; i++) {
      if (i === index) {
        arreglo[i] = true;
      } else {
        arreglo[i] = false;
      }
    }
    setSelectImagen(arreglo);
  }

  //

  useEffect(() => {
    paquete().then(({ data, status }) => {
      switch (status) {
        case 0:
          setPaquetes(data)
          break;
        default:
          mensaje('error', 'Error de conexión');
          break;
      }
    })
  }, []);


  useEffect(() => {
    if (paquete_elegido !== 0 && paquetes.length > 0) {
      if (idioma === 'es') {
        setTotal(paquetes[paquete_elegido - 1].precio)
        cambiarHoverImagen(paquete_elegido - 1);
      } else {
        setTotal(paquetes[paquete_elegido - 1].precio_usd)
        cambiarHoverImagen(paquete_elegido - 1);
      }
    }
  }, [paquetes, paquete_elegido]);

  useEffect(() => {
    if (idioma === 'es') {
      if (paquete_elegido !== 0 && paquetes.length > 0) {
        setTotal(paquetes[paquete_elegido - 1].precio)
        cambiarHoverImagen(paquete_elegido - 1);
      }
    } else {
      if (paquete_elegido !== 0 && paquetes.length > 0) {
        setTotal(paquetes[paquete_elegido - 1].precio_usd)
        cambiarHoverImagen(paquete_elegido - 1);
      }
    }
  }, [idioma]);

  const buscarCodigo = (tamano) => {
    setCargarPantalla(true);
    let valor = "";
    switch (tamano) {
      case 'chico':
        valor = ref_inputxs.current.value;
        break;
      default:
        valor = ref_inputmd.current.value;
        break;
    }
    if (valor !== '' && valor !== ' ') {
      codigo(valor).then(({ data, status }) => {
        switch (status) {
          case 0:
            setValorCodigo(data.id_codigo);
            setPorcentajeCodigo(data.porcentaje);
            mensaje('success', idioma === 'es' ? 'Código aceptado' : 'Accepted code', '');
            break;
          default:
            mensaje('error', idioma === 'es' ? 'Código invalido' : 'Warning', '');
            break;
        }
        setCargarPantalla(false);
      });
    } else {
      mensaje('warning', 'Registra un código', 'Register a code');
      setCargarPantalla(false);
    }

  }

  const irDelante = (adelante) => {
    if(adelante){
      if(paquete_elegido === 1){
        setTotal(paquetes[1].precio)
        cambiarHoverImagen(1);
        setPaqueteElegido(2);
      }else if(paquete_elegido === 2){
        setTotal(paquetes[2].precio)
        cambiarHoverImagen(2);
        setPaqueteElegido(3);
      }else if(paquete_elegido === 3){
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
        setPaqueteElegido(4);
      }else {
        setPaqueteElegido(1);
        setTotal(paquetes[0].precio)
        cambiarHoverImagen(0);
      }
    }else{
      if(paquete_elegido === 1){
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
        setPaqueteElegido(4);
      }else if(paquete_elegido === 2){
        setTotal(paquetes[0].precio)
        cambiarHoverImagen(0);
        setPaqueteElegido(1);
      }else if(paquete_elegido === 3){
        setTotal(paquetes[1].precio)
        cambiarHoverImagen(1);
        setPaqueteElegido(2);
      }else if(paquete_elegido === 4){
        setTotal(paquetes[2].precio)
        cambiarHoverImagen(2);
        setPaqueteElegido(3);
      }else {
        setPaqueteElegido(4);
        setTotal(paquetes[3].precio)
        cambiarHoverImagen(3);
      }
    }
  }

  let ver_paquetes = paquetes.map((objeto, index) => {

    return (
      <Col xs={20} md={10}>
        <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
      </Col>
    )
  });

  let ver_paquetes_movil = paquetes.map((objeto, index) => {

    return (
      <Col span={10}>
        {idioma === 'es' ?
          <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
          :
          <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>}
      </Col>
    )
  });

  let ver_paquetes_2022 = paquetes.map((objeto, index) => {

    return (
      <Col span={24}>
         {/*<Whatsapp idioma={idioma} />*/}
        {idioma === 'es' ?
          <>
          {select_imagen[index] &&
            <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
            <img src={select_imagen[index] ? objeto.url_imagen_hover : objeto.url_imagen} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
          </p>
          }
          </>
          :
          <>
          {select_imagen[index] &&
            <p onClick={() => { setTotal(idioma === 'es' ? objeto.precio : objeto.precio_usd); cambiarHoverImagen(index); setPaqueteElegido(objeto.id_paquete); }} >
              <img src={select_imagen[index] ? objeto.url_imagen_hover_en : objeto.url_imagen_en} alt={objeto.nombre} style={{ width: '100%', cursor: 'pointer' }} />
            </p>
        }
        </>}
      </Col>
    )
  });

  
  const elegirMedio = value => {
    console.log(value);

    if (value === 'Otro') {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }

  }

  useEffect(() => {
    if (datos_registro.length > 0) {
      setConfirmacion(true);
    }
  }, [datos_registro]);

  useEffect(() => {
    if (confirmacion) {
      if (datos_registro[2] > 0) {
        mensaje('success', 'Realiza tu pago', '');
      }
    }
  }, [confirmacion]);

  /*useEffect(() => {
    if (props.match.params.paquete && parseInt(props.match.params.paquete) > 0 && parseInt(props.match.params.paquete) < 5) {
      setPaqueteElegido(parseInt(props.match.params.paquete));
    }
  }, [props]);*/
  const params = useParams();

  useEffect(() => {
    if (params?.paquete && parseInt(params?.paquete) > 0 && parseInt(params?.paquete) <= 5) {
      setPaqueteElegido(parseInt(params?.paquete));
    }else{
      setPaqueteElegido(1);
    }
  }, []);


  const onFailed = async values =>{
    
    switch(values.errorFields[0].name[0]){
      case 'correo': mensaje('warning',idioma === 'es' ? 'Completa el campo correo electrónico' : 'Fill in the email field',''); break;
      case 'nombre': mensaje('warning',idioma === 'es' ? 'Completa el campo nombre' : 'Fill in the name field',''); break;
      case 'ap_paterno': mensaje('warning',idioma === 'es' ? 'Completa el campo apellido paterno' : 'Complete the paternal surname field',''); break;
      case 'ap_materno': mensaje('warning',idioma === 'es' ? 'Completa el campo apellido materno' : 'Complete the maternal surname field',''); break;
      case 'telefono': mensaje('warning',idioma === 'es' ? 'Completa el campo telefono' : 'Complete the telephone field',''); break;
      //case 'confirm': mensaje('warning',idioma === 'es' ? 'Completa el campo contraseña' : 'Fill in the password field',''); break;
      case 'direccion': mensaje('warning',idioma === 'es' ? 'Completa el campo dirección' : 'Fill in the address field',''); break;
      case 'cp': mensaje('warning',idioma === 'es' ? 'Completa el campo codigo postal' : 'Fill in the zip code field',''); break;
      case 'ciudad': mensaje('warning',idioma === 'es' ? 'Completa el campo ciudad' : 'Complete the city field',''); break;
      case 'estado': mensaje('warning',idioma === 'es' ? 'Completa el campo estado' : 'Fill in the state field',''); break;
      case 'pais': mensaje('warning',idioma === 'es' ? 'Completa el campo país' : 'Complete the country field',''); break;
      case 'medio_enterado': mensaje('warning',idioma === 'es' ? 'Completa el campo medio enterado' : 'Fill in the half-aware field',''); break;
      default: mensaje('warning',idioma === 'es' ? 'Faltan campos' : 'Fields are missing',idioma === 'es' ? 'Por favor rellena todos los campos' : 'Please fill in all the fields'); break;

    }
  }

  const onFinish = async values => {

    if (ref_recaptcha.current.getValue() === '' || ref_recaptcha.current.getValue() === ' ') {
      mensaje('warning', idioma === 'es' ? 'Recaptcha inválido' : 'Invalid recaptcha', idioma === 'es' ? 'Por favor verifica tu identidad' : 'Please verify your identity');
      return;
    }

    values['file'] = url_file;

    values.paquete_id = paquete_elegido;
    values["g-recaptcha-response"] = ref_recaptcha.current.getValue();

    if (paquete_elegido === 0) {
      mensaje('warning', idioma === 'es' ? 'Elige un paquete' : 'Choose a package', '');
      return;
    }

    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
    //values.password = values.password.split('').map(letra => acentos[letra] || letra).join('').toString().toLowerCase();

    if (valor_codigo !== 0) {
      values.codigo_id = valor_codigo;
    }

    if (values.medio_enterado === 'Otro') {
      values.medio_enterado = values.otro_medio;
      delete values.otro_medio;
    }

    values.idioma = idioma;
    values.nombre_curso = nom_evento;

    console.log(values);

    setCargarPantalla(true);

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          mensaje('success', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Te enviamos un correo de confirmación de registro al correo' : 'We send you a registration confirmation email to the email' + data[3]);
          setDatosRegistro(data);
          break;
        case 1:
          mensaje('warning', idioma === 'es' ? 'Registrado' : 'Registered', idioma === 'es' ? 'Tu registro ha quedado guardado, pero tuvimos problemas para enviarte un correo de confirmación a la dirección ' : 'Your registration has been saved, but we had problems sending you a confirmation email to the address' + data[3]);
          setDatosRegistro(data);
          break;
        case 2:
          mensaje('warning', idioma === 'es' ? 'Advertencia' : 'Warning', data);
          break;
        default:
          mensaje('error', idioma === 'es' ? 'Error de conexión' : 'Connection error', idioma === 'es' ? 'Inténtalo más tarde.' : 'Try again later');
          break;
      }
      setCargarPantalla(false);
    });

  };

  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        {confirmacion ?
          <RegistroCompleto idioma={idioma} datos={datos_registro} />
          :

          <Row className="contenedor_registro_">
            <Col span={24} className="contendor_banner_registro_">
              <Row>
                <Col xs={{ span: 0, offset: 0 }} md={{ span: 24, offset: 0 }} style={{marginTop:'7%'}}>
                  <img src={banner_registro} style={{ width: '100%' }} />
                </Col>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 0, offset: 0 }}>
                  <img src={banner_registro_movil} style={{ width: '100%' }} />
                </Col>
              </Row>
            </Col>
            <Col span={24} className="contenedor_cintillo_bienvenido">
              <p className="titulo_registro">Bienvenid@ al registro</p>
            </Col>

            <Col span={24}>
              <Form className="login-form"
                layout="vertical"
                name="basic"
                initialValues={{
                  factura: facturar,

                  /*aceptar_terminos: true,
                  nombre: 'Martin',
                  ap_paterno: 'Rodríguez',
                  ap_materno: 'Ayala',
                  correo:'martin@thethree.com.mx',
                  password: '1234567890',
                  confirm: '1234567890',
                  empresa: 'The Three',
                  telefono: '1234567890',
                  miembro_ies: 1,
                  direccion: 'mi direccion',
                  cp: '09090',
                  ciudad: 'CDMX',
                  estado: 'CDMX',
                  pais: 'MEX',
                  medio_enterado: 'Facebook',
        
                  razon_social:'THE THREE',
                  rfc: '12345',
                  cp_factura:'5678',
                  correo_factura:'martin@thethree.com.mx',
                  cfdi:'Por definir (P01)',
                  direccion_factura: 'toda mi direccion',
        
                  numero_miembro: '12345',
                  carrera: 'Ingeniería',
                  semestre: '5to'*/

                }}
                onFinish={onFinish}
                onFinishFailed={onFailed}
              >

                <Row>

                  <Col xs={{ span: 20, offset: 2 }} sm={{ span: 18, offset: 3 }} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>

                    <Row style={{ marginLeft: '20px', marginRight: '20px' }}>

                      <Col xs={{ span: 24, offset: 0 }} className="">
                        <br />
                          <Row justify="space-around">
                          {ver_paquetes}
                            </Row>
                      </Col>
                      <Col span={24}>
                        <p className="subtitulo_registro">
                            <strong>Información de contacto</strong>
                        </p>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="correo"
                          rules={idioma === 'es' ?
                            [
                              {
                                type: 'email',
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu correo electrónico !</span>,
                              },
                            ] :
                            [
                              {
                                type: 'email',
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your email!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Correo electrónico" : "Email"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="nombre"
                          rules={idioma === 'es' ? [

                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa tu nombre!</span>,
                            },
                          ] :
                            [{
                              required: true,
                              message: <span className="label_requerido_registro">Enter your name!</span>,
                            },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Nombre(s)" : "Name"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 11 }}>
                        <Form.Item
                          name="ap_paterno"
                          rules={idioma === 'es' ?
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu apellido paterno!</span>,
                              },
                            ] :
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your paternal last name!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido paterno" : "Last name"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          name="ap_materno"
                          rules={idioma === 'es' ?
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu apellido materno!</span>,
                              },
                            ] :
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your maternal last name!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Apellido materno" : "Last Name"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="telefono"
                          rules={idioma === 'es' ?
                            [
                              {
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa tu teléfono!</span>,
                              },
                            ] :
                            [
                              {
                                message: '...',
                              },
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your phone!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Teléfono" : "Phone"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="empresa"
                          rules={idioma === 'es' ?
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa el nombre de tu empresa!</span>,
                              },
                            ] :
                            [

                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter your company name!</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder="Empresa" />
                        </Form.Item>
                      </Col>
                      
                      {/*
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          rules={idioma === 'es' ?
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa una contraseña!</span>,
                              },
                            ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Please enter a password!</span>,
                              },
                            ]}
                          hasFeedback
                        >
                          <Input.Password className="inputs_registro" placeholder={idioma === 'es' ? "Elige una contraseña" : "Password"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="confirm"
                          dependencies={['password']}
                          hasFeedback
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Confirma tu contraseña!</span>,
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(<span className="label_requerido_registro">Asegúrate que las contraseñas coincidan!</span>);
                              },
                            }),
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Confirm your password!</span>,
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(<span className="label_requerido_registro">Make sure the passwords match!</span>);
                                },
                              }),
                            ]}
                        >
                          <Input.Password className="inputs_registro" placeholder={idioma === 'es' ? "Confirma tu contraseña" : "Confirm your password"} />
                        </Form.Item>
                          </Col>*/}
                      <Col span={24}>
                        <Form.Item
                          name="direccion"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa una dirección</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter an address</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Dirección" : "Address"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 0 }}>
                        <Form.Item
                          name="cp"
                          rules={idioma === 'es' ?
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Ingresa un código postal</span>,
                              },
                            ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Please enter a Postal Code</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Código Postal" : "Postal Code"} />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24, offset: 0 }} md={{ span: 11, offset: 2 }}>
                        <Form.Item
                          name="ciudad"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa una ciudad</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a city</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Ciudad" : "City"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="estado"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa un estado</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a state</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "Estado" : "State"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="pais"
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Ingresa un país</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Enter a country</span>,
                              },
                            ]}
                        >
                          <Input className="inputs_registro" placeholder={idioma === 'es' ? "País" : "Country"} />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="medio_enterado"
                          label={idioma === 'es' ? <span className="texto_label_form">¿Por qué medio te enteraste?</span>
                            : <span className="texto_label_form">How did you find out?</span>}
                          rules={idioma === 'es' ? [
                            {
                              required: true,
                              message: <span className="label_requerido_registro">Elige el medio por el que te enteraste!</span>,
                            },
                          ] :
                            [
                              {
                                required: true,
                                message: <span className="label_requerido_registro">Choose the means by which you found out!</span>,
                              },
                            ]}
                        >
                          <Select onChange={elegirMedio} >
                            <Option value="Facebook">Facebook</Option>
                            <Option value="Twitter">Twitter</Option>
                            <Option value="Instagram">Instagram</Option>
                            {idioma === 'es' ?
                              <Option value="Correo electrónico">Correo electrónico</Option>
                              :
                              <Option value="Correo electrónico">Email</Option>}
                            <Option value="Whatsapp">Whatsapp</Option>
                            {idioma === 'es' ?
                              <Option value="Otro">Otro</Option>
                              :
                              <Option value="Otro">Other</Option>}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        {otro_medio &&
                          (
                            <Form.Item
                              name="otro_medio"
                              label={<span className="texto_label_form">¿Cuál?</span>}
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa el medio por el que te enteraste!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter the means by which you found out!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder="" />
                            </Form.Item>
                          )
                        }
                      </Col>
                      <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item
                          name="factura"
                          valuePropName="checked"
                        >
                          <Checkbox onChange={(e) => { setFacturar(e.target.checked); }}>
                            {idioma === 'es' ?
                              <span className="texto_label_form">¿Deseas facturar?</span>
                              :
                              <span className="texto_label_form">To wish billing?</span>}

                          </Checkbox>
                        </Form.Item>
                      </Col>

                      {facturar &&
                        <>

                          <Col span={24}>
                            {idioma === 'es' ?
                              <p className="subtitulo_registro">
                                Datos de facturación
                        </p>
                              :
                              <p className="subtitulo_registro">
                                Billing information
                        </p>}
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="razon_social"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu razón social!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your social reason!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Razón social" : 'Social reason'} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name="rfc"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu RFC!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your RFC!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder="RFC" />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="cp_factura"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu código postal!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your postal code!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Código postal" : "Postal code"} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11, offset: 2 }}>
                            <Form.Item
                              name="correo_factura"
                              rules={idioma === 'es' ?
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Ingresa tu correo para envío de factura!</span>,
                                  },
                                ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your email to send the invoice!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Correo electrónico para envío de factura" : "Email to send billing"} />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} md={{ span: 11 }}>
                            <Form.Item
                              name="regimen_fiscal"
                              rules={idioma === 'es' ? [

                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Ingresa tu regímen social!</span>,
                                },
                              ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter your tax regime!</span>,
                                  },
                                ]}
                            >
                              <Input className="inputs_registro" placeholder={idioma === 'es' ? "Régimen fiscal" : 'Tax Regime'} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              label={idioma === 'es' ? <span>Dirección (<small>Calle número exterior, numero interior, colonia, municipio o alcaldía, estado</small>)</span> : <span>Direction (<small>Street outside number, inside number, neighborhood, municipality or mayor's office, state</small>)</span>}
                              name="direccion_factura"
                              rules={idioma === 'es'
                                ? [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Ingresa la dirección!</span>,
                                  },
                                ] :
                                [

                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Enter the address!</span>,
                                  },
                                ]}
                            >
                              <Input.TextArea className="inputs_registro" placeholder="" />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="cfdi"
                              label={idioma === 'es' ? <span className="texto_label_form">Uso de CFDI</span> : <span className="texto_label_form">Use of CFDI</span>}
                              rules={idioma === 'es' ? [
                                {
                                  required: true,
                                  message: <span className="label_requerido_registro">Seleccione su uso de CFDI!</span>,
                                },
                              ] :
                                [
                                  {
                                    required: true,
                                    message: <span className="label_requerido_registro">Select your CFDI usage!</span>,
                                  },
                                ]}
                            >
                              {idioma === 'es' ?
                                <Select>
                                  <Option value="ninguna" selected>Elige una opcion</Option>
                                  <Option value="Devoluciones, descuentos o bonificaciones (G02)">Devoluciones, descuentos o bonificaciones (G02)</Option>
                                  <Option value="Dados, troqueles, moldes, matrices y herramental (I05)">Dados, troqueles, moldes, matrices y herramental (I05)</Option>
                                  <Option value="Honorarios médicos, dentales y gastos hospitalarios (D01)">Honorarios médicos, dentales y gastos hospitalarios (D01)</Option>
                                  <Option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</Option>
                                  <Option value="Adquisición de mercancias (G01)">Adquisición de mercancias (G01)</Option>
                                  <Option value="Gastos en general (G03)">Gastos en general (G03)</Option>
                                  <Option value="Construcciones (I01)">Construcciones (I01)</Option>
                                  <Option value="Mobiliario y equipo de oficina por inversiones (I02)">Mobiliario y equipo de oficina por inversiones (I02)</Option>
                                  <Option value="Equipo de transporte (I03)">Equipo de transporte (I03)</Option>
                                  <Option value="Equipo de computo y accesorios (I04)">Equipo de computo y accesorios (I04)</Option>
                                  <Option value="Comunicaciones telefónicas (I06)">Comunicaciones telefónicas (I06)</Option>
                                  <Option value="Comunicaciones satelitales (I07)">Comunicaciones satelitales (I07)</Option>
                                  <Option value="Otra maquinaria y equipo (I08)">Otra maquinaria y equipo (I08)</Option>
                                  <Option value="Gastos médicos por incapacidad o discapacidad (D02)">Gastos médicos por incapacidad o discapacidad (D02)</Option>
                                  <Option value="Gastos funerales (D03)">Gastos funerales (D03)</Option>
                                  <Option value="Donativos (D04)">Donativos (D04)</Option>
                                  <Option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)</Option>
                                  <Option value="Aportaciones voluntarias al SAR (D06)">Aportaciones voluntarias al SAR (D06)</Option>
                                  <Option value="Primas por seguros de gastos médicos (D07)">Primas por seguros de gastos médicos (D07)</Option>
                                  <Option value="Gastos de transportación escolar obligatoria (D08)">Gastos de transportación escolar obligatoria (D08)</Option>
                                  <Option value="Pagos por servicios educativos (colegiaturas) (D10)">Pagos por servicios educativos (colegiaturas) (D10)</Option>
                                  <Option value="Por definir (P01)">Por definir (P01)</Option>
                                </Select>
                                :
                                <Select>
                                  <Option value="ninguna" selected>Choose an option</Option>
                                  <Option value="Devoluciones, descuentos o bonificaciones (G02)">Returns, discounts or bonuses (G02)</Option>
                                  <Option value="Dados, troqueles, moldes, matrices y herramental (I05)">Dices, troqueles, molds, dies and tooling (I05)</Option>
                                  <Option value="Honorarios médicos, dentales y gastos hospitalarios (D01)">Medical, dental and hospital fees (D01)</Option>
                                  <Option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Deposits in savings accounts, premiums based on pension plans</Option>
                                  <Option value="Adquisición de mercancias (G01)">Acquisition of merchandise (G01)</Option>
                                  <Option value="Gastos en general (G03)">General expenses (G03)</Option>
                                  <Option value="Construcciones (I01)">Construcciones (I01)</Option>
                                  <Option value="Mobiliario y equipo de oficina por inversiones (I02)">Office furniture and equipment for investments (I02)</Option>
                                  <Option value="Equipo de transporte (I03)">Transport equipment (I03)</Option>
                                  <Option value="Equipo de computo y accesorios (I04)">Computer equipment and accessories (I04)</Option>
                                  <Option value="Comunicaciones telefónicas (I06)">Telephone communications (I06)</Option>
                                  <Option value="Comunicaciones satelitales (I07)">Satellite communications (I07)</Option>
                                  <Option value="Otra maquinaria y equipo (I08)">Other machinery and equipment (I08)</Option>
                                  <Option value="Gastos médicos por incapacidad o discapacidad (D02)">Medical expenses for disability or disability (D02)</Option>
                                  <Option value="Gastos funerales (D03)">Funeral expenses (D03)</Option>
                                  <Option value="Donativos (D04)">Donations (D04)</Option>
                                  <Option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación) (D05)">Real interest actually paid for mortgage loans (casa habitación) (D05)</Option>
                                  <Option value="Aportaciones voluntarias al SAR (D06)">Voluntary contributions to the SAR (D06)</Option>
                                  <Option value="Primas por seguros de gastos médicos (D07)">Premiums for health insurance (D07)</Option>
                                  <Option value="Gastos de transportación escolar obligatoria (D08)">Mandatory school transportation expenses (D08)</Option>
                                  <Option value="Pagos por servicios educativos (colegiaturas) (D10)">Payments for educational services (colegiaturas) (D10)</Option>
                                  <Option value="Por definir (P01)">To define (P01)</Option>
                                </Select>
                              }
                            </Form.Item>
                          </Col>

                        </>
                      }


                      <Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item
                          name="aceptar_terminos"
                          valuePropName="checked"
                          rules={idioma === 'es' ?
                            [
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Acepta el aviso de privacidad</span>),
                              },
                            ] :
                            [
                              {
                                validator: (_, value) =>
                                  value ? Promise.resolve() : Promise.reject(<span className="label_requerido_registro">Accept the privacy notice</span>),
                              },
                            ]}
                        >
                          <Checkbox>
                            {idioma === 'es' ?
                              <span className="texto_label_form">He leido y acepto el <strong><a style={{color:'#0055cd'}} target="_blank" href="">aviso de privacidad.</a></strong></span>
                              :
                              <span className="texto_label_form">I have read and accept the <strong><a style={{color:'#0055cd'}} target="_blank" href="">aotice of privacy.</a></strong></span>}

                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24} style={{ display: 'non' }}>
                        <ReCAPTCHA ref={ref_recaptcha}
                          sitekey="6LfOy2InAAAAAOUGqZsPxwyFM6f_5IWMmcdpPc8-"
                        />
                      </Col>
                      <Col xs={24} md={0}>
                        <br />
                      </Col>

                      <Col span={24}>
                        <br /><br />
                      </Col>
                    </Row>
                    </Col>
                    <Col xs={{ span: 0, offset: 0 }} lg={{ span: 24, offset: 0 }} className="">

                        <Row justify="center" align="middle">
                          <Col span={16} offset={4} style={{ textAlign: 'left',position:'absolute',bottom:'5%',left:'10px',width:'100%' }}>
                          <p style={{color:'#ffffff'}}>{idioma === 'es' ? 'En caso de tener un código de cortesía, por favor ingresalo en el campo de abajo:' : 'If you have a courtesy code, please enter it in the field below:'}</p>
                          <input ref={ref_inputmd} className="inputs_codigo" placeholder={idioma === 'es' ? "Código de cortesía" : "Courtesy code"} name="descuento" data="descuento" />

<button className="btn_codigo" type="button" onClick={() => { buscarCodigo('grande') }} ><strong>USAR</strong></button>
                         
                        </Col>
                        </Row>
                        <br /><br />
                    </Col>
                    <Col span={24}>

                    <br /><br />
                      <Row justify="center" align="middle" className="contenedor_totales">
                        
                        <Col span={15}>
                          <p className="total_registro">Total (Impuestos incluidos): {formatter.format(ver_total - ((ver_total * porcentaje_codigo) / 100))}</p>
                        </Col>
                        <Col span={24}>
                          <Form.Item>

                            <p style={{ textAlign: 'center', display:'non' }}>
                              <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer', zIndex: 99 }} htmlType="submit">
                              <p className="btn_registrate_home"><strong>REGÍSTRATE</strong></p>
                              </button>
                             {/* <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20IES%20México.">
                                <button type="button" style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }}>
                                    Tengo problemas con mi registro
                                </button>
                              </a>*/}
                            </p>

                          </Form.Item>

                        </Col>
                      </Row>
                    </Col>
                  
                
                </Row>

              </Form>
            </Col>
          </Row>
        }
      </Spin>
    </>

  );
};

export default withRouter(Registro);
