import React, { useEffect, useState } from 'react';
import '../../assets/css/general.css';
import {Element, scroller} from 'react-scroll';
import {Switch, Route, useLocation } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Evento from '../evento/Evento';
import Sala from '../conferencias/Sala';
import Registro from '../registro/Registro';

import Admin from '../admin/Admin';
import VisualizarPreguntas from '../admin/VisualizarPreguntas';
import EstadisticaGrafica from '../conferencias/EstadisticaGrafica';

import CerrarSesion from '../sesion/CerrarSesion';
import Error from "../auth/Error";

import PayPal from '../../utils/PayPal';
import PayPalIndependiente from '../../utils/PayPalIndependiente';


import { AuthContext } from '../../utils/AuthContext';

import '../../assets/css/global.css';
import Expo from '../expo/Expo';
import Paneles from '../evento/Paneles';
import Conferencias from '../conferencias/Conferencias';

export const Master = () => {

  const [idioma, setIdioma] = useState('es');

  const [mostrar_login, setMostrarLogin] = useState(false);
  //let { usuario, datos } = useContext(Auth);

  const secciones = [
    {componente:<Evento idioma={idioma}/>, url:'/'},
    {componente:<Expo idioma={idioma}/>, url:'/salon-conferencias'},
    {componente:<Paneles idioma={idioma}/>, url:'/panel'},
    {componente:<Conferencias idioma={idioma}/>, url:'/on-demand'},
    
    {componente:<PayPalIndependiente idioma={idioma}/>, url:'/pago'},
    {componente:<Registro setMostrarLogin={setMostrarLogin} idioma={idioma}/>, url:'/registro/:paquete?'},
    {componente:<PayPal idioma={idioma}/>, url:'/paypal'},
    {componente:<EstadisticaGrafica  idioma={idioma}/>, url:'/graficas'},

  ];
  
  const secciones_auth = [
    {componente:Sala, url:'/conferencias'},
    {componente:Admin, url:'/admin'},
    {componente:VisualizarPreguntas, url:'/visualizar-preguntas'},
    {componente:EstadisticaGrafica, url:'/graficas'},
    {componente:CerrarSesion, url:'/cerrar-sesion'}
  ];

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact render={(props)=>{
          return(
            seccion.componente
          );
        }} />
      );
    });

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} idioma={idioma} />
      );
    });

    const location = useLocation();

    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }

    useEffect(() => {
      if (location.hash === "") {
        scroller.scrollTo("header");
      } else {
        scroller.scrollTo(location.hash);
      }
    });
  
    useEffect(() => {
      sleep(1000).then(() => {
        if (location.hash === "") {
          scroller.scrollTo("header");
        } else {
          scroller.scrollTo(location.hash);
        }
      });
    }, []);

  return(
    <>
    <AuthContext >
      <Element name="header" />
      <Header setMostrarLogin={setMostrarLogin} mostrar_login={mostrar_login} setIdioma={setIdioma} idioma={idioma} />
    <Switch>
      {visualizar_secciones}
      {visualizar_secciones_auth}
      <Route path='/*' exact component={Error} idioma={idioma} />
    </Switch>
    <Footer idioma={idioma}/>
    </AuthContext>
    </>
  );
}