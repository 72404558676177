import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "../../utils/AuthContext";
import axios from "axios";

import Firebase from '../../utils/firebaseConfig';

import img_logo_1 from '../../assets/images/temporal/600X200.png';

import { Link } from 'react-router-dom';


let logos = [
    { index: 0, img_src: img_logo_1, nombre: 'acuity-brands', url:'/salon-conferencias/acuity-brands' },
]


function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

const ExpoMovil = (params, props) => {

    const { num_items } = params;

    const app = new Firebase();
    const { usuario, datos } = useContext(Auth);
    const history = useHistory();
  const location = useLocation();
  const [standNombre, setSatndNombre] = useState('');
  const nombre_evento = 'ies-2023'; 
  const [dia, setDia] = useState(1);
  const { idioma } = props;


  const almacenarClicStand = (nombre_pat) => {
    let metrica = {dia: dia, ...datos}
    if(usuario){
      app.verificar_objeto_dia(nombre_evento+`/stands/${nombre_pat}/stand`,'correo',datos.correo,dia, (data) => {
        if(!data){
          app.escribir(nombre_evento+`/stands/${nombre_pat}/stand`,undefined,metrica,()=>{});
        }
      });
    }else{
      app.escribir(nombre_evento+`/stands/${nombre_pat}/stand_clic`,undefined,{clic:1, dia: dia},()=>{});
    }
  };
  


  const eventHandler = (req) => {
    almacenarClicStand(req);
    console.log('reeeeeww',req);
  };

    const settingslogos = {
        className: "center",
            centerMode: true,
            infinite: true,
            slidesToShow: num_items,
            pauseOnHover:false,
            slidesToScroll: 1,
            autoplay:true,
            arrows:false,
            autoplaySpeed: 200,
            speed:2000,
            dots:false
    };
    

    //logos = shuffle(logos);
    const div_logos = logos.map(logo => {
        return(
          <Col  xs={{span:10}} className="img_patrocindaor">
            <Link onClick={()=> eventHandler(logo.nombre)} to={logo.url}><img src={logo.img_src} alt={logo.nombre} className="img_logo" style={{ width:'100%'}}  /></Link>
          </Col>
        )
    });

    return (
      <>
      <Row justify='center' align='middle'>
        <Col span={24}>
        
        </Col>
      {div_logos}
      </Row>
      </>
      
    );

};

export default ExpoMovil;