import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import { Row, Col } from "antd";
import img_stand from "../../assets/images/temporal/1920x800.png";
import img_stand_en from "../../assets/images/temporal/1920x800.png";
import ImageMapper from "react-image-mapper";
import { useHistory } from "react-router-dom";
import { Auth } from "../../utils/AuthContext";
import axios from "axios";

import '../../assets/css/general.css';
import Firebase from '../../utils/firebaseConfig';

const ExpoWeb = (props) => {
  const [widthp, setWidth] = useState(window.innerWidth);
  const [heightp, setHeight] = useState(window.innerHeight);
  const history = useHistory();
  const { usuario, datos } = useContext(Auth);
  const [standNombre, setSatndNombre] = useState('');
  const app = new Firebase();
  const { idioma } = props;

  const location = useLocation();
  
  const nombre_evento = 'ies-2023'; 
  const [dia, setDia] = useState(1);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  const almacenarClicStand = (nombre_pat) => {
    let metrica = {dia: dia, ...datos}
    if(usuario){
      app.verificar_objeto_dia(nombre_evento+`/stands/${nombre_pat}/stand`,'correo',datos.correo,dia, (data) => {
        if(!data){
          app.escribir(nombre_evento+`/stands/${nombre_pat}/stand`,undefined,metrica,()=>{});
        }
      });
    }else{
      app.escribir(nombre_evento+`/stands/${nombre_pat}/stand_clic`,undefined,{clic:1, dia: dia},()=>{});
    }
  };

  const eventHandler = (req) => {
   if(req === 'galeria')
   {
    //setSatndNombre(req);
    //almacenarClicStand(req);
    //location.pathname.split('/').pop();
    let urls = 'https://flic.kr/s/aHBqjAEskG';
    window.open(urls);
    //history.push(urls);
   }else if(req === 'on-demand')
   {
    //setSatndNombre(req);
    //almacenarClicStand(req);
    //location.pathname.split('/').pop();
    let urls = '/on-demand';
    //window.open(urls);
    history.push(urls);
   }else{
    setSatndNombre(req);
    almacenarClicStand(req);
    location.pathname.split('/').pop();
    let urls = '/salon-conferencias/'+req;
    history.push(urls);
   }
  };

  const IMAGE_URL = idioma === 'es' ? img_stand : img_stand_en;
  const AREAS_MAP = {
    name: "my-map",
    areas: [
      { name: "on-demand", href:"/on-demand", coords:[916,794,1574,926], shape:"rect",strokeColor:'rgba(0,0,0,0)'},
      { name: "galeria", href:"https://flic.kr/s/aHBqjAEskG", coords:[2280,787,2938,936], shape:"rect",strokeColor:'rgba(0,0,0,0)'},
      //{ name: "", href:"", coords:[1564,980,2300,1129], shape:"rect"},
      { name: "acuity-brands", href:"/salon-conferencias/acuity-brands", coords:[834,1309,1191,1323,1197,1458,1248,1462,1248,1570,824,1570], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "construlita", href:"/salon-conferencias/construlita", coords:[1430,1296,1796,1292,1793,1407,1840,1424,1840,1546,1447,1546], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "cooper", href:"/salon-conferencias/cooper", coords:[2040,1277,2410,1284,2400,1409,2461,1440,2461,1538,2040,1535], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "crestron", href:"/salon-conferencias/", coords:[2600,1289,2963,1302,2960,1431,3011,1441,3007,1553,2597,1546], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "current", href:"/salon-conferencias/current", coords:[439,1575,802,1575,806,1687,850,1714,843,1826,429,1823], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "lamp", href:"/salon-conferencias/lamp", coords:[1308,1596,1664,1596,1664,1725,1715,1731,1715,1840,1298,1853], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "ledvance", href:"/salon-conferencias/ledvance", coords:[2251,1597,2607,1601,2610,1723,2668,1726,2661,1859,2234,1855], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "lutron", href:"/salon-conferencias/lutron", coords:[3055,1555,3421,1558,3424,1684,3462,1687,3475,1813,3051,1809], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "signify", href:"/salon-conferencias/signify", coords:[494,1918,860,1925,857,2040,911,2057,904,2186,494,2169], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "tci", href:"/salon-conferencias/tci", coords:[1209,1920,1566,1923,1576,2052,1616,2062,1620,2171,1196,2177], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "toljy", href:"/salon-conferencias/toljy", coords:[2193,1891,2563,1898,2556,2040,2597,2050,2600,2162,2186,2162], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      { name: "zeraus", href:"/salon-conferencias/zeraus", coords:[2983,1926,3346,1926,3350,2062,3404,2082,3404,2177,2980,2184], shape:"poly",strokeColor:'rgba(0,0,0,0)'},
      
    ],
  };
  return (
    <>
      <Row>
        <Col xs={24}>
          <ImageMapper
            src={IMAGE_URL}
            map={AREAS_MAP}
            width={widthp}
            imgWidth={3840}
            target="_blank"
            onClick={(area) => eventHandler(area.name)}
          />
          
        </Col>
      </Row>
    </>
  );
};

export default ExpoWeb;
