import React, {useEffect, useState} from 'react';

import PayPal from '../../utils/PayPal';

const RegistroCompleto = (props) => {

    const {idioma, datos} = props;
    const [pago_aceptado, setPagoAceptado] = useState(false);

    return(
        <>
            {!pago_aceptado &&
                <PayPal idioma={idioma} datos={datos} />
            }

        </>
    );
}

export default RegistroCompleto;
