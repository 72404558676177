import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import { pago_registro } from './ConexionServidor';
import { notification } from 'antd';
import { Row, Col, Spin } from 'antd';

import '../assets/css/paypal.css';

import logo_paypal from '../assets/images/sitio/Banners/Sinergia_BannerRegistro.jpg';
import logo_paypal_movil from '../assets/images/sitio/Banners/Sinergia_BannerRegistro_movil.jpg';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

const mensaje = (tipo, titulo, texto) => {
  notification[tipo]({
    message: titulo,
    description: texto,
  });
}

const PayPal = (props) => {

  const { datos } = props;

  //let datos = [0, 1220, 100,0,0,'USD'];

  const [pago_exitoso, setPagoExitoso] = useState(0);
  const [cargar_pantalla, setCargarPantalla] = useState(false);

  useEffect(() => {
    if (datos[2] === 0) {
      setPagoExitoso(1);
    }
  }, [datos]);

  const { idioma } = props;
  console.log(idioma);

  return (

    <>
      <Spin spinning={cargar_pantalla} delay={0}>
        <br /><br />
        {
          pago_exitoso === 0 ?

            <Row>
              <Col xs={0} md={24}>
                <img src={logo_paypal} style={{ width: '100%', marginTop:'5%' }} />
              </Col>
              <Col xs={24} md={0}>
                <img src={logo_paypal_movil} style={{ width: '100%', marginTop:'5%' }} />
              </Col>
              <Col xs={24}>
              <p className="contenedor_cintillo_bienvenido registro_exitoso">
                <strong>REGISTRO EXITOSO</strong> <CheckCircleOutlined style={{ color: '#ffffff' }} /></p>
              </Col>
              <Col span={24}>

                {idioma === 'es' ?
                  <p className="texto_paypal">
                    <strong>Por favor realiza el pago</strong>
                    <br />
                    Total (Impuestos incluidos):
                  </p>
                  :
                  <p className="texto_paypal">
                    <strong>Please make the payment</strong>
                    <br />
                    Total:
                  </p>}
                <p className="total_paypal">
                  <span>${new Intl.NumberFormat().format(datos[2])}<sup>{datos[5]}</sup></span>
                </p>
              </Col>
              <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                {idioma === 'es' ?
                  <p className="mini_texto_paypal">
                    Al hacer clic en "Paypal" sera dirigido a Paypal para completar el pago de manera mas segura.
                  </p>
                  :
                  <p className="mini_texto_paypal">
                    By clicking on "Checkout", you will be directed to PayPal to complete the payment safely.
                  </p>
                }
              </Col>
              <Col xs={{ span: 18, offset: 4 }} md={{ span: 8, offset: 8 }}>
                <br />
                <PayPalButton
                  style={{
                    layout: 'vertical',
                    color: 'blue',
                    shape: 'pill',
                    label: 'paypal'
                  }}
                  currency_code={datos[5]}
                  disable_funding='card'
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: `${datos[2]}`
                        }
                      }],
                    });
                  }}
                  onApprove={(data, actions) => {
                    // Capture the funds from the transaction
                    return actions.order.capture().then(function (details) {
                      const pago = {
                        registro_id: datos[0],
                        orden: details,
                        estado: details.status
                      };

                      // OPTIONAL: Call your server to save the transaction
                      pago_registro(pago).then(({ status, data }) => {
                        switch (status) {
                          case 0:
                            switch (details.status) {
                              case 'COMPLETED':
                                mensaje('success', 'Registro completo', '');
                                setPagoExitoso(1);
                                break;
                              default:
                                mensaje('warning', 'Pago incompleto', '');
                                setPagoExitoso(2);
                                break;
                            }
                            break;
                          default:
                            mensaje('success', 'Registro incompleto', data);
                            break;
                        }
                      });
                    });
                  }}

                  options={{
                    clientId: "AUjAimpO4Ykg2GKuwAAUDDwH1kqvw5Ek9YJ1MhekYeeZPJztYMlQFJ9vuhoVPAhnMuJiCzOMZl5A4pWp",
                    currency: datos[5],
                    disableFunding: 'card'
                  }}
                />
                {/*AYThC-rZqeZdzNj6xZol4s_ITwQ0okGddtauUDzcvsyWCU210yhSErbL6zFOMn3Ze46NK3GUijA4Z_RA*/}
              </Col>
            </Row>

            :
            pago_exitoso === 1 ?

              <Row>
                <Col xs={24} >
                  <Row>
                  <Col xs={0} md={24}>
                <img src={logo_paypal} style={{ width: '100%', marginTop:'5%' }} />
              </Col>
              <Col xs={24} md={0}>
                <img src={logo_paypal_movil} style={{ width: '100%', marginTop:'5%' }} />
              </Col>
              <Col xs={24}>
              <p className="contenedor_cintillo_bienvenido registro_exitoso">
                <strong>PAGO EXITOSO</strong> <CheckCircleOutlined style={{ color: '#ffffff' }} /></p>
              </Col>
                    <Col span={24}>
                      <p className="registro_texto_ubicacion">
                        <br />
                        <><strong>GRACIAS POR TU REGISTRO,</strong><br />
                            Te esperamos en:<br />
                            <strong>Corporativo SIEMENS, CDMX</strong></></p>
                            <p className="texto_registro_exitoso">
                          En breve recibirá un correo de confirmación a la siguiente dirección
                          <br />
                          <strong>{datos[3]}</strong>
                        </p>
                        <p className="texto_registro_exitoso color_azul">
                          Si no encuentra el mail confirmación en su bandeja de entrada, es posible el mensaje<br />
                          haya sido enviado a la carpeta de SPAM / NO DESEADOS.
                        </p>
                        </Col>
                  </Row>
                </Col>
              </Row>
              :

              <Row>
                 <Col xs={24}>
                <img src={logo_paypal} style={{ width: '100%', marginTop:'5%' }} />
              </Col>
              <Col xs={24}>
              <p className="contenedor_cintillo_bienvenido registro_exitoso">
              <strong>PAGO RECHAZADO <CloseCircleOutlined style={{ color: 'white' }} /></strong></p>
              </Col>
                <Col span={18} offset={3}>
                  <Row>


                    <Col span={24}>
                      {idioma === 'es' ?
                        <p className="texto_registro_exitoso">
                          Hubo un error al procesar su pago.
                          <br />
                          Por favor contáctese con el banco emisor de su tarjeta.
                        </p>
                        :
                        <p className="texto_registro_exitoso">
                          There was an error processing your payment.
                          <br />
                          Please contact the bank that issued your card.
                        </p>}
                      {idioma === 'es' ?
                        <Link to="/" className="boton_inicio">
                          VOLVER AL INICIO
                        </Link>
                        :
                        <Link to="/" className="boton_inicio">
                          BACK TO HOME
                        </Link>}
                    </Col>
                  </Row>
                </Col>
              </Row>


        }
        <br /><br /><br />
      </Spin>
    </>



  );

}

export default PayPal;