import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Form, Input, Button, Select, Spin, notification } from "antd";
import  Firebase  from "../../utils/firebaseConfig";
import {Link} from 'react-router-dom';
import { Auth } from '../../utils/AuthContext';
import Errores from './Errores'
import '../../assets/css/registro.css';
import '../../assets/css/login.css';
import { revisar_status_registro } from '../../utils/ConexionServidor';


const app = new Firebase();

let function_recaptcha;


const nombre_webinar = 'demo';

const mensaje = (tipo,titulo, texto) =>{

  notification[tipo]({
    message: titulo,
    description: texto,
  });

}
const Login = ({ history, onResponse, onResponseCerrarLogin, idioma }) => {

    const { usuario } = useContext(Auth);
    const [error, seterror] = useState('');
    const [correo_recuperar, setCorreoRecuperar] = useState('');
    const [ingresar_codigo, setIngresarCodigo] = useState(false);
    const [cargando_login, setCargandoLogin] = useState(false);
    
    const ref_codigo = useRef(null);
    const ref_form_login = useRef(null);

    useEffect(() => {

        if (usuario) {
            //alert('usuario registrado');
        }
    }, [history, usuario]);

    const onFinish = async values => {

        setCargandoLogin(true);
        values.acceso = true;

        revisar_status_registro(values).then(data => {
            switch(data.status){
                case 0:
                    console.log(data.data);
                    localStorage.setItem('usuario',JSON.stringify(data.data));

                    app.app.auth().signInAnonymously()
                        .then(() => {
                            setCargandoLogin(false);
                            onResponse();
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                    });
                    
                break;
                case 1:
                    mensaje('error',idioma === 'es' ? 'Usuario no encontrado': 'User not found','');
                    setCargandoLogin(false);
                break;
                case 2:
                    mensaje('error',idioma === 'es' ? 'Pago pendiente': 'Pending payment',idioma === 'es' ? 'Dirígete a seminariodeiluminacion.iesmexico.org/pago para realizar tu pago': 'Go to seminariodeiluminacion.iesmexico.org/pago to make your payment');
                    setCargandoLogin(false);
                break;
                default:
                    mensaje('warning','Aviso',data.data);
                    setCargandoLogin(false);
                break;
            }
        });

    };

    return (
        <Row className="contenedor_registro">
            <Col span={24}>
            <Spin spinning={cargando_login}>
            
            
                    
                    <Form className="login-form" 
                    onFinish={onFinish} 
                    ref={ref_form_login}
                    layout="vertical"
                    initialValues={{
                        prefix: '+52',
                      }} >
                        
                        <Form.Item
                                name="correo"
                                label={<span style={{ color:'black'}}>{idioma === 'es' ? 'Correo electrónico' : 'Email'}</span>}
                                rules={[
                                    {
                                      type:'email',
                                      required: true,
                                      message: <span style={{color:'red'}}>{idioma === 'es' ? 'Ingresa tu correo' : 'Enter your email'}</span>,
                                    },
                                ]}
                                onChange= { (e)=> { setCorreoRecuperar(e.target.value); }}
                            >
                                <Input style={{backgroundColor:'#083280'}} />
                            </Form.Item>
                        
                            <Form.Item
                                name="pass"
                                label={<span style={{ color:'black'}}>{idioma === 'es' ? 'Contraseña' : 'Password'}</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: <span style={{color:'red'}}>{idioma === 'es' ? 'Ingresa tu contraseña' : 'Enter your password'}</span>,
                                    },
                                ]}
                                onChange= { (e)=> { setCorreoRecuperar(e.target.value); }}
                            >
                                <Input style={{backgroundColor:'#083280'}} />
                            </Form.Item>

                        {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                        <Form.Item>
<br />
                                    <p style={{textAlign:'center'}}>
                                    <button style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }} htmlType="submit">
                                        <p className="btn_registrate_login">{idioma === 'es' ?  'ENTRAR' : 'ENTER'}</p>
                                    </button>
                        </p>
                        <p style={{textAlign:'center'}}>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20inicio de sesión%20en%20IES%20México.">
                                <button type="button" style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }}>
                                    {idioma === 'es' ? 'Tengo problemas con mi inicio de sesión' : 'I have a problem with my login'}
                                </button>
                            </a>
                        </p>
                        <p style={{textAlign:'center'}}>
                            <strong>{idioma === 'es' ? '¿No tienes cuenta?' : 'Do you have not a count?'}</strong>
                            <br />
                            <Link to="/registro">
                                <button style={{ border: 'none', backgroundColor:'#ffffff', cursor:'pointer' }}>
                                    {idioma === 'es' ? 'Regístrate' : 'Sign up'}
                                </button>
                            </Link>
                                    
                                   
                        </p>
                        </Form.Item>

                    </Form>
            
            
</Spin>
</Col>
            

           
        
        </Row>

    );
}

export default Login;
